import React from "react";
import Contractors from "./company";
import TechDetails from "../Details";
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";

const High = (props) => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Contractors />
      </Route>
      <Route path={`${path}/companyDetails`}>
        <TechDetails />
      </Route>
    </Switch>
  );
};

export default High;
