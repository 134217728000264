import * as Constants from '../utils/constants';

export const startLogin = () => dispatch => {
  dispatch({
    type: Constants.START_LOGIN,
    payload: 'Start',
  });
};

export const loginFail = load => dispatch => {
  dispatch({
    type: Constants.LOGIN_FAIL,
    payload: load,
  });
};

export const loginSuccess = load => dispatch => {
  dispatch({
    type: Constants.LOGIN_SUCCESS,
    payload: load,
  });
};

export const loginReset = () => dispatch => {
  dispatch({
    type: Constants.LOGIN_RESET,
    payload: 'reset',
  });
};