
const initialState = {
    category: "",
    showTowers: false,
    path: "",
    company: "",
    tech: null,
    completed: null
  };
  
  const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_CATEGORY': {
        return {
          ...state,
          category: action.payload
        };
      }
      case 'OPEN_PAGE' : {
        return {
          ...state,
          showTowers: true
        }
      }
      case 'CLOSE_PAGE' : {
        return {
          ...state,
          showTowers: false
        }
      }
      case 'SET_PATH' : {
        return {
          ...state,
          path: action.payload
        }
      }
      case 'SET_COMPANY' : {
        return {
          ...state,
          company: action.payload
        }
      }
      case 'SET_TECH_DETAILS' : {
        return {
          ...state,
          tech: action.payload
        }
      }
      case 'SET_COMPLETED' : {
        return {
          ...state,
          completed: action.payload
        }
      }

      default: {
        return state;
      }
    }
  };
  
  export default CategoryReducer;
  