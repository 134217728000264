import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';
import monitorReducersEnhancer from '../utils/monitor';
import loggerMiddleware from '../utils/logger';
import rootReducer from '../reducers';

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [loggerMiddleware, thunk],
    preloadedState,
    enhancers: [monitorReducersEnhancer],
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
