import React from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Dialog as Dia, Pane, Table, Card as Cd, toaster } from "evergreen-ui"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from "react-switch";
import { useSelector, useDispatch } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { zoomIn } from 'react-animations';
import Radium from 'radium';
import { PieChart } from 'react-minimal-pie-chart';
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Select from 'react-dropdown-select'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import RedoIcon from '@material-ui/icons/Redo';
import MaterialTable from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import { getTechs, createtech, edittech, deletetech, getByComp, assignto, gettechbyid } from '../../utils/gql';
import { useQuery, useMutation } from '@apollo/client'
import Pdf from "react-to-pdf";
import { setTechDetails } from "actions/_setCategory";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import update from 'immutability-helper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompanyDetails = (props) => {

    const [value, setValue] = React.useState(0);
    const [isShown, setIsShown] = React.useState(false)
    const [info, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [jobs, setJobs] = React.useState([]);
    const [techie, setTech] = React.useState([]);
    const [assigne, assignTech] = React.useState("");
    const [technical, setTechnical] = React.useState([]);
    const [techData, setTecData] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [num, setNumber] = React.useState(0);
    const [active, setActive] = React.useState([])
    const [failed, setFail] = React.useState([])
    const [completed, setComplete] = React.useState([])

    let tmp_cat = false;
    let role = "";
    const History = useHistory();
    const { url, path } = useRouteMatch();
    const dispatch = useDispatch();

    const state = useSelector(state => ({
        sector: state.sector.sector,
        highsiteID: state.id.id,
        sectorID: state.sector.sectorID,
        highsite: state.name.name,
        comp_id: state.category.company._id,
        company: state.category.company

    }))

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const magicNumber = number => {
        let tmp = number.split('');
        if (tmp[0] == "2" && tmp[1] == "5" && tmp[2] == "6" && tmp[3] == "7") {
            if (tmp.length == 12) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    const formatDate = date => `${moment(date).format('LL')}`
    const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true
        }

        return false
    }

    const { error, loading, data } = useQuery(getTechs, { variables: { id: state.comp_id }, errorPolicy: "all", fetchPolicy: "cache-and-network" });
    const tech = useMutation(createtech, { errorPolicy: 'all', update: (cache, { data: { createTech } }) => {
        let tmp_tech = {};
        if(createTech != null){
            if(createTech.status == true){
                tmp_tech.label = createTech.name;
                tmp_tech.value = createTech._id
            }
            setTech(prev => [...prev, tmp_tech])
            setNumber(prev => prev + 1)
        }
            
      }
     })
    const editech = useMutation(edittech, { errorPolicy: 'all' })
    const deltech = useMutation(deletetech, { errorPolicy: 'all' })
    const getbyid = useMutation(gettechbyid, {
        errorPolicy: "all", onError: err => {
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })

            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");
            }
        },
        onCompleted: data => {
            if (data.getTechByID != null) {
                dispatch(setTechDetails(data.getTechByID))
                History.push({ pathname: `${url}/techinfo` });
            }
        }
    })
    const totech = useMutation(assignto, {
        errorPolicy: 'all', onError: err => {
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })

            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");
            }
        }
    })

    const aldetail = useQuery(getByComp, {
        variables: { id: state.comp_id }, errorPolicy: "all", fetchPolicy: "cache-and-network", onError: err => {
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })

            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");

            }
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleByID = id => {
        getbyid[0]({ variables: { id } })

    }



    const ref = React.createRef();
    React.useEffect(() => {
        if (data) {
            if (data.getTech != null) {
                let arr = [];
                let tmp_ = [...data.getTech];
                tmp_.sort(dynamicSort("name"));
                setData(tmp_);
                data.getTech.map(item => {
                    if (item.status == true) {
                        arr.push({
                            label: item.name,
                            value: item._id
                        })
                    }
                })
                setTech(arr);
            }
        }


    }, [loading])

    React.useEffect(() => {
        if (aldetail.data) {
            if (aldetail.data.getByCompany != null) {
                let activ = [];
                let complet = [];
                let fail = [];
                let finArr = [];
                //console.log(aldetail.data.getByCompany)
                let tmp_ = [...aldetail.data.getByCompany].map(item => {
                    let temp = update(item, {
                        assignedOn: {$set: formatDate(item.assignedOn)}
                    })
                    finArr.push(temp);
                });
                finArr.map(item => {
                    if (item.state == "active") {
                        activ.push(item)
                    }
                    if (item.state == "failed") {
                        fail.push(item)
                    }
                    if (item.state == "completed") {
                        complet.push(item)
                    }
                })

                setActive(activ);
                setFail(fail);
                setComplete(complet)
                finArr.sort(dynamicSort("firstName"));

                setJobs(finArr);
            }
        }


    }, [aldetail.loading])

    React.useEffect(() => {
        setNumber(state.company.number)
    }, [])

    const handleTech = async() => {
        //console.log(technical)
        if (assigne == "") {
            toaster.danger("Technician not selected");
            return;
        }

        if(technical.assigned_tech){
            if(technical.assigned_tech._id == assigne){
                toaster.danger("This job is already assigned to this client");
                return;
            }

            else{
                setShow(false);
               try{
                const { data, errors} = await totech[0]({ variables: { id: technical._id, tech_id: assigne } })
                if(errors && Array.isArray(errors)){
                    toaster.danger(errors[0].message)
                }

                if(data && data.assignToTech != null){
                    toaster.success("Technician successfully assigned");
                }

            }
            catch(e){
                toaster.danger("Something wrong happened");
            }
           
            }
        }
        else {
            setShow(false);

            try{
                const { data, errors} = await totech[0]({ variables: { id: technical._id, tech_id: assigne } })
                if(errors && Array.isArray(errors)){
                    toaster.danger(errors[0].message)
                }

                if(data && data.assignToTech != null){
                    toaster.success("Technician successfully assigned");
                }

            }
            catch(e){
                toaster.danger("Something wrong happened");
            }
           
        }
    }


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const defaultLabelStyle = {
        fontSize: '2px',
        fontFamily: 'sans-serif',
        textColor: 'white'
    };

    function changeDesc(value, desc, projects) {
        for (var i in projects) {
            if (projects[i].value == value) {
                projects[i].role = desc;
                break; //Stop this loop, we found it!
            }
        }
    }


    const classes = useStyles();
    return (
        <div style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>

            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite, styles.bounce]}>{'Japotech'}</h3>
                </CardHeader>
                <CardBody>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="All technicians" {...a11yProps(0)} />
                        <Tab label="Full details" {...a11yProps(1)} />
                        <Tab label="All Jobs" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <Card>
                            <CardHeader>
                                <p className={classes.cardCategory}>All Technicians</p>
                            </CardHeader>
                            <CardBody>

                                <MaterialTable

                                    options={{
                                        searchFieldStyle: {
                                            color: "black",
                                            borderBottomColor: "black",
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10],
                                        search: true,
                                        filter: true,
                                        actionsColumnIndex: -1
                                    }}
                                    columns={[
                                        {
                                            title: "Added on", field: "date", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{formatDate(row.date)}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Role", field: "role", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.role}</p>
                                                )
                                            }, editComponent: props => {

                                                return (
                                                    <Select
                                                        multi={false}
                                                        isClearable

                                                        placeholder={props.value}
                                                        options={[{ label: "admin", value: "admin" }, { label: "tech", value: "tech" }]}
                                                        onChange={values => {
                                                            role = values[0].value
                                                            props.onChange(values[0].value);

                                                        }}
                                                        style={{
                                                            zIndex: 5,
                                                            backgroundColor: '#fff',
                                                            borderRadius: 5,
                                                            flex: 1,
                                                            width: 150
                                                        }}
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            title: "Names", field: "name", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.name}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Contact", field: "contact", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Email", field: "email", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.email}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Status", field: "status", render: row => {

                                                return (

                                                    <Switch checked={row.status} disabled />

                                                )
                                            },
                                            editComponent: props => {

                                                return (
                                                    <Switch checked={props.value} onChange={e => {
                                                        props.onChange(e);
                                                        tmp_cat = e
                                                    }} />
                                                )
                                            }
                                        }



                                    ]}

                                    data={info.map(o => ({ ...o }))}
                                    onRowClick={(e, RowData) => {
                                        handleByID(RowData._id)
                                    }}

                                    isLoading={loading || tech[1].loading || editech[1].loading || deltech[1].loading || getbyid[1].loading}
                                    title={""}
                                    //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                                    components={{
                                        OverlayLoading: (props) => (
                                            <div
                                                {...props}
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            >
                                                <BounceLoader color="#00acc1" size={50} {...props} />
                                            </div>
                                        )
                                    }}
                                    icons={{
                                        Add: props => (
                                            <div>
                                                <Fab aria-label="add" {...props} style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                                                    <AddBoxIcon size="small" />
                                                </Fab>
                                            </div>
                                        )
                                    }}

                                    editable={{

                                        onRowAdd: newData =>
                                            new Promise((resolve) => {
                                                const str = newData.name
                                                const result = validateEmail(newData.email);
                                                const numRes = magicNumber(newData.contact);

                                                // console.log(numRes);
                                                if (str == "" || str.length < 4) {

                                                    toaster.danger('The name should be greater than 5 characters');
                                                    resolve();
                                                }
                                                else if (result == false || newData.email == "") {
                                                    toaster.danger('Wrong email address supplied. try again');
                                                    resolve();
                                                }
                                                else if (numRes == false || newData.contact == "") {
                                                    toaster.danger('Wrong contact supplied. Contact must be in +25675..... format');
                                                    resolve();
                                                }
                                                else {
                                                    tech[0]({ variables: { name: str, contact: newData.contact, email: newData.email, company: state.comp_id, date: new Date().toISOString(), status: tmp_cat, role } }).then(res => {
                                                        let techs = [...info]
                                                        if (res.data.createTech !== null) {

                                                            if (res.data.createTech.role == "admin") {
                                                                changeDesc("admin", "tech", techs)
                                                                setData([...techs, res.data.createTech])
                                                                toaster.success("New technician successfully added");
                                                                tmp_cat = false;
                                                                role = "";
                                                                resolve();

                                                            }
                                                            else {
                                                                setData(prev => [...prev, res.data.createTech])
                                                                toaster.success("New technician successfully added");
                                                                tmp_cat = false;
                                                                role = "";
                                                                resolve();
                                                            }
                                                        }
                                                        if (res.errors) {
                                                            if (Array.isArray(res.errors)) {
                                                                res.errors.map((error, index) => {
                                                                    toaster.danger(error.message, {
                                                                        id: `err_${index}`
                                                                    })
                                                                })
                                                                resolve();
                                                            }
                                                            else {
                                                                toaster.danger("Something is wrong with your connection. Please check your internet");
                                                                resolve();
                                                            }
                                                        }

                                                    }).catch(err => {

                                                        console.log(err)
                                                        resolve();
                                                    })

                                                }
                                            }),

                                        onRowUpdate: (newData) =>
                                            new Promise((resolve) => {
                                                const str = newData.name
                                                const result = validateEmail(newData.email);
                                                const numRes = magicNumber(newData.contact);

                                                // console.log(numRes);
                                                if (str == "" || str.length < 3) {

                                                    toaster.danger('The name should be greater than 5 characters');
                                                    resolve();
                                                }
                                                else if (result == false || newData.email == "") {
                                                    toaster.danger('Wrong email address supplied. try again');
                                                    resolve();
                                                }
                                                else if (numRes == false || newData.contact == "") {
                                                    toaster.danger('Wrong contact supplied. Contact must be in +25675..... format');
                                                    resolve();
                                                }


                                                else {
                                                    const datArr = [...info]
                                                    editech[0]({ variables: { id: newData._id, name: str, contact: newData.contact, email: newData.email, role: newData.role, status: newData.status } }).then(res => {
                                                        //console.log(res)
                                                        if (res.data.editTech !== null) {
                                                            const arr = datArr.findIndex(item => item._id == newData._id);
                                                            datArr[arr] = res.data.editTech;
                                                            setData(datArr);
                                                            toaster.success("Operation successful", {
                                                                zIndex: 20,
                                                            })
                                                            resolve()
                                                        }
                                                        if (res.errors) {
                                                            if (Array.isArray(res.errors)) {
                                                                res.errors.map((error, index) => {
                                                                    toaster.danger(error.message, {
                                                                        id: `err_${index}`
                                                                    })
                                                                })
                                                                resolve();
                                                            }
                                                            else {
                                                                toaster.danger("Something is wrong with your connection. Please check your internet");
                                                                resolve();
                                                            }
                                                        }

                                                    }).catch(err => {

                                                        console.log(err)
                                                        resolve();
                                                    })
                                                }
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise((resolve) => {
                                                deltech[0]({ variables: { id: oldData._id, company: state.comp_id } }).then(res => {
                                                    if (res.data.deleteTech != null) {
                                                        const arr = info.filter(e => e._id != res.data.deleteTech._id);
                                                        setData(arr)
                                                        toaster.success("Operation successful", {
                                                            zIndex: 20,
                                                        })
                                                        resolve();
                                                    }
                                                    if (res.errors) {
                                                        if (Array.isArray(res.errors)) {
                                                            res.errors.map((error, index) => {
                                                                toaster.danger(error.message, {
                                                                    id: `err_${index}`
                                                                })
                                                            })
                                                            resolve();
                                                        }
                                                        else {
                                                            toaster.danger("Something is wrong with your connection. Please check your internet");
                                                            resolve();
                                                        }
                                                    }

                                                })
                                            })

                                    }}
                                />

                            </CardBody>
                        </Card>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Card>
                            <CardHeader style={styles.bounce}>Details</CardHeader>
                            <CardBody>
                                <GridContainer container justify="center" alignItems="center">
                                    <GridItem xs={12} sm={12} md={6}>
                                        <PieChart
                                            animate={true}
                                            animationDuration={1000}
                                            totalValue={completed.length + active.length + failed.length}
                                            radius={30}
                                            className={styles.bounce}
                                            segmentsShift={(index) => 0}
                                            data={[
                                                { title: 'Completed', value: completed.length, color: '#a8ff9e' },
                                                { title: 'Pending', value: active.length, color: '#fff3a6' },
                                                { title: 'Failed', value: failed.length, color: '#f72a23' },
                                            ]}
                                            onClick={(data, index) => handleClickOpen()}
                                            onMouseOver={(e, index) => {

                                            }}
                                            label={({ dataEntry }) => dataEntry.percentage > 0 ? dataEntry.title : null}
                                            labelStyle={{
                                                ...defaultLabelStyle,
                                            }}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <List className={classes.root} style={{ marginTop: "28%" }}>
                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <TodayIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={moment(state.company.date).format("MMM Do YY")} />
                                            </ListItem>
                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <PersonIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={state.company.name} />
                                            </ListItem>
                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <PeopleIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={`${num} Technicians`} />
                                            </ListItem>
                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <LocationOnIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={state.company.location} />
                                            </ListItem>
                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <PhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={state.company.contact} />
                                            </ListItem>

                                            <ListItem role={undefined} dense button onClick={() => { }}>
                                                <ListItemIcon>
                                                    <ContactPhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={state.company.email} />
                                            </ListItem>
                                        </List>
                                        <Table>
                                            <Table.Head>
                                                <Table.TextHeaderCell style={{ backgroundColor: '#a8ff9e' }}>Completed</Table.TextHeaderCell>
                                                <Table.TextHeaderCell style={{ backgroundColor: '#fff3a6' }}>Pending</Table.TextHeaderCell>
                                                <Table.TextHeaderCell style={{ backgroundColor: '#f72a23' }}>Failed</Table.TextHeaderCell>
                                                <Table.TextHeaderCell>Total</Table.TextHeaderCell>
                                            </Table.Head>
                                            <Table.Body height={240}>
                                               
                                                    <Table.Row key={1}>
                                                        <Table.TextCell>{completed.length}</Table.TextCell>
                                                        <Table.TextCell>{active.length}</Table.TextCell>
                                                        <Table.TextCell isNumber>{failed.length}</Table.TextCell>
                                                        <Table.TextCell isNumber>{completed.length + active.length + failed.length}</Table.TextCell>
                                                    </Table.Row>
                                            
                                            </Table.Body>
                                        </Table>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <Card>
                            <CardHeader style={styles.bounce}>Details</CardHeader>
                            <CardBody>
                                <MaterialTable

                                    options={{
                                        searchFieldStyle: {
                                            color: "black",
                                            borderBottomColor: "black",
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10],
                                        search: true,
                                        filter: true,
                                        actionsColumnIndex: -1,
                                        rowStyle: rowData => {
                                            if(rowData.state === "completed") {
                                              return {backgroundColor: '#6bfac6'};
                                            }
                                            if(rowData.state === "failed") {
                                                return {backgroundColor: '#fa6666'};
                                              }
                                              if(rowData.state === "rejected") {
                                                return {backgroundColor: '#fa2205'};
                                              }
                                            
                                            return {};
                                          }
                                    }}
                                    columns={[
                                        {
                                            title: "Name", field: "firstName", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Account", field: "account", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Assigned On", field: "assignedOn", render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assignedOn}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Location", field: "city", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Contact", field: "contact", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Type", field: "mode", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.mode}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "AssignedTo", field: "assigned_tech.name", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_tech == null ? "Unassiged" : row.assigned_tech.name}</p>
                                                )
                                            }
                                        },
                                        {
                                            title: "State", field: "state", editable: false, render: row => {
                                                return (
                                                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.state}</p>
                                                )
                                            }
                                        }

                                    ]}


                                    data={jobs.map(o => ({ ...o }))}
                                    onRowClick={(e, RowData) => {
                                        setIsShown(true)
                                    }}
                                    actions={[
                                        {
                                            icon: RedoIcon,
                                            tooltip: 'Assign to Contractors',
                                            onClick: (event, rowData) => {
                                                setShow(true);
                                                setTechnical(rowData)
                                                //setInfo(rowData);
                                            }
                                        }
                                    ]}


                                    isLoading={aldetail.loading || totech[1].loading}
                                    title={""}
                                    //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                                    components={{
                                        OverlayLoading: (props) => (
                                            <div
                                                {...props}
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            >
                                                <BounceLoader color="#00acc1" size={50} {...props} />
                                            </div>
                                        )
                                    }}


                                />
                            </CardBody>
                        </Card>
                    </TabPanel>

                    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    Sound
                                </Typography>
                                <Button autoFocus color="inherit" onClick={handleClose}>
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar>


                        <Pdf targetRef={ref} filename="code-example.pdf">
                            {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                        </Pdf>
                        <MaterialTable
                            ref={ref}
                            options={{
                                searchFieldStyle: {
                                    color: "black",
                                    borderBottomColor: "black",
                                },
                                pageSize: 10,
                                pageSizeOptions: [10],
                                search: true,
                                filter: true,
                                actionsColumnIndex: -1
                            }}
                            columns={[
                                {
                                    title: "Names", field: "Names", render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Names}</p>
                                        )
                                    }
                                },
                                {
                                    title: "Account", field: "Account", render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Account}</p>
                                        )
                                    }
                                },
                                {
                                    title: "Category", field: "Category", editable: false, render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Category}</p>
                                        )
                                    }
                                },
                                {
                                    title: "Date", field: "Date", editable: false, render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Date}</p>
                                        )
                                    }
                                },
                                {
                                    title: "Package", field: "Package", editable: false, render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Package}</p>
                                        )
                                    }
                                },
                                {
                                    title: "Contact", field: "Contact", editable: false, render: row => {
                                        return (
                                            <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.Contact}</p>
                                        )
                                    }
                                },


                            ]}

                            data={[
                                {
                                    Names: 'Kaggwe james', Account: 'LUG004748', Contact: "0773536273", Category: "Installation", Package: "GPON", Date: "12-09-2021"
                                },
                                {
                                    Names: 'Issaz Mutenyi', Account: 'ltu-90074', Contact: "0773538473", Category: "Troubleshoot", Package: "GPON", Date: "19-10-2021"
                                },
                                {
                                    Names: 'Matovu John', Account: 'AU-908474', Contact: "0773993473", Category: "Installation", Package: "GPON", Date: "19-10-2021"
                                },
                            ]}
                            onRowClick={(e, RowData) => {
                                setIsShown(true)
                            }}

                            isLoading={false}
                            title={""}
                            //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                            components={{
                                OverlayLoading: (props) => (
                                    <div
                                        {...props}
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <BounceLoader color="#00acc1" size={50} {...props} />
                                    </div>
                                )
                            }}

                            actions={[
                                {
                                    icon: PrintIcon,
                                    tooltip: 'Print out copy',
                                    onClick: (event, rowData) => {
                                        // Do save operation
                                    }
                                }
                            ]}

                        />
                    </Dialog>

                    <Pane>

                        <Dia
                            isShown={show}
                            title="Assign installation to Technicians"
                            onCloseComplete={() => setShow(false)}
                            onConfirm={handleTech}
                            onCancel={() => setShow(false)}
                        >
                            <Pane height={200} width="100%" >
                                <Pane>
                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Assign to contractors'}
                                        options={techie}
                                        onChange={values => { values.length == 0 ?  assignTech("") : assignTech(values[0].value) }}
                                        style={{
                                            zIndex: 5,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 250
                                        }}
                                    />
                                </Pane>
                            </Pane>
                        </Dia>

                    </Pane>

                </CardBody>
            </Card>
        </div >
    );
};

const styles = {
    bounce: {
        animation: 'x 2s',
        animationName: Radium.keyframes(zoomIn, 'zoomIn')
    }
}

export default CompanyDetails;
