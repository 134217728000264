export const openPage = () => dispatch => {
    dispatch({
      type: 'OPEN_PAGE',
      payload: null,
    });
  };

  export const closePage = () => dispatch => {
    dispatch({
      type: 'CLOSE_PAGE',
      payload: null,
    });
  };

  export const currentRoute = path => dispatch => {
      dispatch({
          type: 'SET_PATH',
          payload: path
      })
  }