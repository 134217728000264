import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { useAlert } from "react-alert";
import MaterialTable, { MTableToolbar } from "material-table";
import Fab from "@material-ui/core/Fab";
import BounceLoader from "react-spinners/MoonLoader";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  SideSheet,
  Paragraph,
  Heading,
  Pane,
  TextInputField,
  Card as Cards,
  Position,
  Label,
  Textarea,
  toaster,
} from "evergreen-ui";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Switch from "react-switch";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  queryTowers,
  queryApns,
  newTower,
  towerEditor,
  towerDel,
  makeTowers,
  sendToAll,
  mailAllClients,
} from "../../utils/gql";
import { useSelector, useDispatch } from "react-redux";
import { setHighsite, setTower } from "../../actions/_setHigh";
import { setID } from "../../actions/_setID";
import { setApn } from "../../actions/_setApns";
import { storeTowers } from "../../actions/_setTower";
import BounceLoader2 from "react-spinners/SyncLoader";
import AddBoxIcon from "@material-ui/icons/AddBox";
import WifiIcon from "@material-ui/icons/Wifi";
import CachedIcon from "@material-ui/icons/Cached";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { openPage, closePage, currentRoute } from "../../actions/_choosePage";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontFamily: "Roboto",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  container4: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
};

const useStyles = makeStyles(styles);

export default function Towers(props) {
  const classes = useStyles();

  const [mailBody, setMail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [info, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [sms, setSMS] = React.useState(true);
  const [all, setAll] = React.useState([]);
  const [, setFlag] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [openText, setText] = React.useState(false); // controls the message slide in pane
  const tabRef = React.createRef(); // creating a reference to the material table
  const tabRef2 = React.createRef();
  const alert = useAlert();
  const History = useHistory(); // Using the history object exposed by react-router-dom
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch(); // tracking the react-router-dom routes
  const state = useSelector((state) => ({
    name: state.name,
    auth: state.auth,
    status: state.category.showTowers,
  }));

  const refreshClients = useMutation(makeTowers, {
    errorPolicy: "all",
    onError: (err) => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`,
          });
        });
      } else {
        toaster.danger(
          "Something is wrong with your connection. Please check your internet"
        );
      }
    },
    onCompleted: (data) => {
      console.log(data);
    },
  });

  // for capitalizing any statement passed

  // Navigating to the details page
  const handleSpecific = (id) => {
    apns[0]({ variables: { name: id } });
  };

  // open the side bar for registering new highsite

  const openBar = () => {
    setOpen(true);
  };

  // close the side bar for registering highsite
  const closeBar = () => {
    setOpen(false);
  };
  // toggling between message mode type
  const setMessageMode = () => {
    setSMS(!sms);
  };

  // getting rid of white spaces
  const isEmptyString = (str) => str === null || str.match(/^ *$/) !== null;

  // fetching all highsites via graphql

  React.useEffect(() => {
    dispatch(currentRoute(path));
  }, []);

  const toggleText = () => {
    setText(!openText);
  };

  const send_msg = useMutation(sendToAll, { errorPolicy: "all" });
  const mail_all = useMutation(mailAllClients, { errorPolicy: "all" });

  const handleMessage = async () => {
    if (message == "" || message.length < 20) {
      toaster.danger("Message should be atleast 20 characters in length");
      return;
    } else {
      try {
        const { errors, data } = await send_msg[0]({
          variables: { message, user: state.auth.username },
        });
        if (errors && Array.isArray(errors)) {
          toaster.danger(errors[0].message);
        }

        if (data != null) {
          const tmp = JSON.parse(data.notifyAll.message);
          if (tmp.isError == true) {
            toaster.danger(tmp.message);
          } else {
            toaster.success(
              "The SMS message has been successfully shared with the clients"
            );
            toggleText();
            setMessage("");
            //showDisplay(true)
          }
        }
      } catch (e) {
        // console.log(e)
        toaster.danger("Something happened");
      }
    }
  };

  const handleMessage2 = async () => {
    //console.log(mailBody, "=====>", subject)
    if (
      mailBody == "" ||
      mailBody.length < 20 ||
      subject == "" ||
      subject.length < 5
    ) {
      toaster.danger(
        "Message should be atleast 20 characters in length and subject 5"
      );
      return;
    } else {
      try {
        const { errors, data } = await mail_all[0]({
          variables: { message: mailBody, type: "email", subject },
        });
        if (errors && Array.isArray(errors)) {
          toaster.danger(errors[0].message);
        }

        //console.log(data, )

        if (data != null) {
          const tmp = JSON.parse(data.mailAll.message);
          if (tmp.isError == true) {
            toaster.danger(tmp.message);
          } else {
            toaster.success(
              "The email message has been successfully shared with the clients"
            );
            toggleText();
            setSubject("");
            setMail("");
            //showDisplay(true)
          }
        }
      } catch (e) {
        console.log(e);
        toaster.danger("Something wrong happened");
      }
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const { loading, data, error, refetch } = useQuery(queryTowers, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: (err) => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`,
          });
        });
      } else {
        toaster.danger(
          "Something is wrong with your connection. Please check your internet"
        );
      }
    },
  });
  const changetower = useMutation(towerEditor, {
    errorPolicy: "all",
    onError: (err) => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`,
          });
        });
      } else {
        toaster.danger(
          "Something is wrong with your connection. Please check your internet"
        );
      }
    },
  });
  const apns = useLazyQuery(queryApns, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      dispatch(setApn(data.getApns));
      History.push({ pathname: `${url}/Apns` });
    },
  });
  const tower = useMutation(newTower, {
    errorPolicy: "all",
  });

  const handleRefresh = async () => {
    refreshClients[0]({ variables: { name: "Makindye" } })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const deletetower = useMutation(towerDel, {
    errorPolicy: "all",
    onError: (er) => {
      //console.log(er, "---->caught up")
    },
    onCompleted: (da) => {
      // console.log(da, "response dataaaa")
    },
  });

  // const searchall = useQuery(searchAll, {
  //   errorPolicy: "all",
  // });

  React.useEffect(() => {
    //console.log(tabRef.current.dataManager)
    if (data) {
      if (data.getTowers != null) {
        let tmp_ = [...data.getTowers];
        //console.log(typeof data.getTowers)
        tmp_.sort(dynamicSort("tower"));
        setData(tmp_);
        dispatch(storeTowers(tmp_));
      }

      //setOffset(sites.data.info.cursor)
      //console.log(sites)
    }
    //console.log(tabRef.current)
  }, [loading]);

  // React.useEffect(() => {
  //   if (searchall.data) {
  //     if (searchall.data.getAll != null) {
  //       setAll(searchall.data.getAll);
  //     }
  //   }
  // }, [searchall.loading]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Liquid Tech Clients</h4>
            <p className={classes.cardCategoryWhite}>All Active Towers</p>
          </CardHeader>
          <CardBody>
            {/* {state.status ? (
              <MaterialTable
                tableRef={tabRef}
                options={{
                  searchFieldStyle: {
                    color: "black",
                    borderBottomColor: "black",
                  },
                  pageSize: 10,
                  pageSizeOptions: [10],
                  search: true,
                  filter: true,
                }}
                columns={[
                  {
                    title: "Names",
                    field: "firstName",
                    render: (row) => {
                      return (
                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                          {row.firstName}
                        </p>
                      );
                    },
                  },
                  {
                    title: "IP",
                    field: "managementIP",
                    editable: false,
                    render: (row) => {
                      return (
                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                          {row.managementIP}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Tower",
                    field: "tower",
                    editable: false,
                    render: (row) => {
                      return (
                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                          {row.tower == "tower" ? "Unknown" : row.tower}
                        </p>
                      );
                    },
                  },
                  {
                    title: "APN",
                    field: "access.name",
                    editable: false,
                    render: (row) => {
                      return (
                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                          {row.access.length > 0
                            ? row.access[0].name
                            : "Unknown"}
                        </p>
                      );
                    },
                  },
                ]}
                data={all.map((o) => ({ ...o }))}
                onRowClick={(e, RowData) => {
                  dispatch(setHighsite(RowData.tower));
                  dispatch(setID(RowData._id));
                  handleSpecific(RowData.tower);
                  dispatch(setTower(RowData.tower));
                }}
                isLoading={searchall.loading || apns[1].loading}
                title={""}
                //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                components={{
                  OverlayLoading: (props) => (
                    <div
                      {...props}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <BounceLoader color="#00acc1" size={50} {...props} />
                    </div>
                  ),

                  Toolbar: (props) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: 10,
                      }}
                    >
                      <MTableToolbar {...props} />
                      <div style={{ marginLeft: 10 }}>
                        <Fab
                          aria-label="add"
                          onClick={() => {
                            setText(true);
                          }}
                          style={{ color: "white", backgroundColor: "#00acc1" }}
                          size="small"
                        >
                          <TextsmsIcon size="small" />
                        </Fab>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <Fab
                          aria-label="add"
                          onClick={() => dispatch(closePage())}
                          style={{ color: "white", backgroundColor: "#00acc1" }}
                          size="small"
                        >
                          <WifiIcon size="small" />
                        </Fab>
                      </div>
                    </div>
                  ),
                }}
                icons={{
                  Add: (props) => (
                    <div>
                      <Fab
                        aria-label="add"
                        {...props}
                        style={{ color: "white", backgroundColor: "#00acc1" }}
                        size="small"
                      >
                        <AddBoxIcon size="small" />
                      </Fab>
                    </div>
                  ),
                }}
              />
            ) : ( */}
            <MaterialTable
              tableRef={tabRef2}
              options={{
                searchFieldStyle: {
                  color: "black",
                  borderBottomColor: "black",
                },
                pageSize: 10,
                pageSizeOptions: [10],
                search: true,
                actionsColumnIndex: -1,
              }}
              columns={[
                {
                  title: "Tower",
                  field: "tower",
                  render: (row) => {
                    return (
                      <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                        {row.tower}
                      </p>
                    );
                  },
                },

                {
                  title: "APs",
                  field: "apns",
                  editable: false,
                  render: (row) => {
                    return (
                      <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                        {row.apns}
                      </p>
                    );
                  },
                },
                {
                  title: "New Clients",
                  field: "newClients",
                },
                {
                  title: "NetBlocks",
                  field: "netBlock",
                  render: (row) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {row.netBlock.map((item, index) => {
                          return (
                            <p
                              style={{
                                paddingLeft: 5,
                                fontFamily: "Roboto",
                                fontSize: 15,
                              }}
                            >{`${item.block}`}</p>
                          );
                        })}
                      </div>
                    );
                  },
                  editable: false,
                },
              ]}
              data={info.map((o) => ({ ...o }))}
              onRowClick={(e, RowData) => {
                dispatch(setHighsite(RowData.tower));
                dispatch(setID(RowData._id));
                handleSpecific(RowData.tower);
                dispatch(setTower(RowData.tower));
              }}
              isLoading={
                loading ||
                apns[1].loading ||
                tower[1].loading ||
                deletetower[1].loading ||
                refreshClients[1].loading
              }
              title={""}
              //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
              components={{
                OverlayLoading: (props) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <BounceLoader color="#00acc1" size={50} {...props} />
                  </div>
                ),

                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <MTableToolbar {...props} />
                    <div></div>
                    <div style={{ marginLeft: 10 }}>
                      <Fab
                        aria-label="add"
                        onClick={handleRefresh}
                        style={{ color: "white", backgroundColor: "#00acc1" }}
                        size="small"
                      >
                        <CachedIcon size="small" />
                      </Fab>
                    </div>
                  </div>
                ),
              }}
              editable={{
                onRowAdd:
                  state.auth.role !== "liquid_care"
                    ? (newData) =>
                        new Promise((resolve) => {
                          //usable[0]();
                          const str = newData.tower;
                          //const result = validateIp(newData.ip)
                          if (str == "" || str.length < 5) {
                            toaster.danger(
                              "Something is wrong with tower name supplied. it should be greater than 5 characters"
                            );
                            resolve();
                          } else {
                            tower[0]({ variables: { tower: newData.tower } })
                              .then((res) => {
                                console.log(res);
                                if (res.data.createNewTower !== null) {
                                  setData((prev) => [
                                    ...prev,
                                    res.data.createNewTower,
                                  ]);
                                }
                                if (res.errors) {
                                  if (Array.isArray(res.errors)) {
                                    res.errors.map((error, index) => {
                                      toaster.danger(error.message, {
                                        id: `err_${index}`,
                                      });
                                    });
                                    resolve();
                                  } else {
                                    toaster.danger(
                                      "Something is wrong with your connection. Please check your internet"
                                    );
                                    resolve();
                                  }
                                }
                              })
                              .catch((err) => console.log(err));

                            resolve();
                          }
                        })
                    : null,

                onRowUpdate:
                  state.auth.role !== "liquid_care"
                    ? (newData) =>
                        new Promise((resolve) => {
                          if (newData.tower == "" || newData.tower.length < 5) {
                            toaster.danger("kindly check the tower name");
                            resolve();
                          } else {
                            //console.log(newData)
                            const datArr = [...info];
                            changetower[0]({
                              variables: {
                                id: newData._id,
                                tower: newData.tower,
                              },
                            })
                              .then((res) => {
                                if (res.data.editTower != null) {
                                  const arr = datArr.findIndex(
                                    (item) => item._id == newData._id
                                  );
                                  datArr[arr] = newData;
                                  setData(datArr);
                                  resolve();
                                }
                                if (res.errors) {
                                  if (Array.isArray(res.errors)) {
                                    res.errors.map((error, index) => {
                                      toaster.danger(error.message, {
                                        id: `err_${index}`,
                                      });
                                    });
                                    resolve();
                                  } else {
                                    toaster.danger(
                                      "Something is wrong with your connection. Please check your internet"
                                    );
                                    resolve();
                                  }
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                                resolve();
                              });
                          }
                        })
                    : null,
                onRowDelete:
                  state.auth.role !== "liquid_care"
                    ? (oldData) =>
                        new Promise((resolve) => {
                          deletetower[0]({
                            variables: { id: oldData._id },
                          }).then((res) => {
                            console.log(res);

                            if (res.data.deleteTower != null) {
                              const arr = info.filter(
                                (e) => e._id != res.data.deleteTower._id
                              );
                              setData(arr);
                              resolve();
                            }
                            if (res.errors) {
                              if (Array.isArray(res.errors)) {
                                res.errors.map((error, index) => {
                                  toaster.danger(error.message, {
                                    id: `err_${index}`,
                                  });
                                });
                                resolve();
                              } else {
                                toaster.danger(
                                  "Something is wrong with your connection. Please check your internet"
                                );
                                resolve();
                              }
                            }
                          });

                          //console.log(oldData)
                        })
                    : null,
              }}
              icons={{
                Add: (props) => (
                  <div>
                    <Fab
                      aria-label="add"
                      {...props}
                      style={{ color: "white", backgroundColor: "#00acc1" }}
                      size="small"
                    >
                      <AddBoxIcon size="small" />
                    </Fab>
                  </div>
                ),
              }}
              localization={{
                body: {
                  addTooltip: "Add new Tower",
                },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <React.Fragment>
        <SideSheet
          isShown={openText}
          onCloseComplete={() => {
            setText(false);
          }}
          position={Position.BOTTOM}
        >
          <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
            <Pane padding={16}>
              <Heading size={600}>Message clients</Heading>
              <Paragraph size={400}>Inform clients via SMS or email</Paragraph>
            </Pane>
          </Pane>
          <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
            <Cards
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Paragraph size={400}>
                {sms ? "Send SMS to clients" : "Send email to clients"}
              </Paragraph>
              <Switch
                onChange={setMessageMode}
                checked={sms}
                checkedIcon={<PhoneAndroidIcon color="#00acc1" />}
                uncheckedIcon={<EmailIcon color="#00acc1" />}
                onColor="#00acc1"
                offColor="#00acc1"
              />
            </Cards>
            <Cards
              backgroundColor="white"
              elevation={0}
              flexDirection="column"
              padding={10}
              display="flex"
            >
              {!sms ? (
                <Pane>
                  <TextInputField
                    label="Subject"
                    inputWidth={350}
                    placeholder="Message subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <Label htmlFor="textarea-2" marginBottom={4} display="block">
                    Message body
                  </Label>
                  <Textarea
                    id="textarea-1"
                    width={350}
                    placeholder="Your message to clients...."
                    value={mailBody}
                    onChange={(e) => setMail(e.target.value)}
                  />
                  <Cards display="flex" flexDirection="row" width={350}>
                    <Button color="info" rounded block onClick={handleMessage2}>
                      {mail_all[1].loading ? (
                        <BounceLoader2 color="white" />
                      ) : (
                        <p>Send</p>
                      )}
                    </Button>
                  </Cards>
                </Pane>
              ) : (
                <>
                  <Label htmlFor="textarea-2" marginBottom={4} display="block">
                    SMS Message
                  </Label>
                  <Textarea
                    id="textarea-3"
                    width={350}
                    placeholder="Your sms to clients...."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Cards display="flex" flexDirection="row" width={350}>
                    <Button color="info" rounded block onClick={handleMessage}>
                      {send_msg[1].loading ? (
                        <BounceLoader2 color="white" />
                      ) : (
                        <p>Send</p>
                      )}
                    </Button>
                  </Cards>
                </>
              )}
            </Cards>
          </Pane>
        </SideSheet>
      </React.Fragment>
    </GridContainer>
  );
}
