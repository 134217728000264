import React from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Pane, Table, Card as Cd } from "evergreen-ui"
import { useSelector } from 'react-redux';
import { zoomIn } from 'react-animations';
import Radium from 'radium';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import jsPDF from "jspdf";
import Pdf from "react-to-pdf";
import moment from "moment";
import { saveAs } from 'file-saver';
import { prodUrl, devUrl } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompletedDetails = (props) => {

    const [value, setValue] = React.useState(0);
    const [isShown, setIsShown] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [city, setCity] = React.useState("");
    const formatDate = date => `${moment(date).format('LL')}`;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function downloadImage(imageSrc) {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)

        const link = document.createElement('a')
        link.href = imageURL
        link.download = 'image file name here'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const download = link => {
        var element = document.createElement("a");
        var file = new Blob(
          [
            link
          ],
          { type: "image/*" }
        );
        element.href = URL.createObjectURL(file);
        element.download = `${state.details.firstName}.png`;
        element.click();
      };



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const state = useSelector(state => ({
        details: state.category.completed,

    }))

    const generatePdf = () => {
        // alert("am called")
        // var doc = new jsPDF("p", "pt", "a4");
        // doc.html(document.querySelector("#mypdf"), {
        //     callback: (pdf) => {
        //         var pcount = pdf.internal.getNumberOfPages();
        //         pdf.deletePage(pcount);
        //         pdf.save(`${state.details.firstName}.pdf`)
        //     }
        // })
        saveAs(`${prodUrl}/${state.details._id}.png`, `${state.details.firstName}.png`);
    }

    const ref = React.createRef();

    React.useEffect(() => {

        //console.log(state.details)

        if (state.details.city != "not available") {
            setCity(state.details.city)
        }
        if (state.details.street != "not available") {
            setCity(state.details.street)
        }
        if (state.details.street == "not available" && state.details.city == "not available") {
            setCity("unknown")
        }
    }, [])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const defaultLabelStyle = {
        fontSize: '2px',
        fontFamily: 'sans-serif',
        textColor: 'white'
    };

    const formatD = date => `${moment(date).format('LL')}`


    const classes = useStyles();
    return (
        <div style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>

            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite, styles.bounce]}>{state.details.firstName}</h3>

                </CardHeader>
                <CardBody>
                    <Pane style={{ marginBottom: 5 }}>
                        <Button onClick={() => generatePdf()}>Download form</Button>
                    </Pane>
                    <Cd display="flex">

                        <Pane flex="2" style={{ padding: '2%' }} id="mypdf">
                            <img src={`${prodUrl}/${state.details._id}.png`} width="600" height="600" alt="No SLA Document found" />

                        </Pane>

                        <Pane flex="1">
                            <Table>
                                <Table.Body>

                                    <Table.Row>
                                        <Table.TextCell>
                                            Full names
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.firstName}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Account
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.account}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Location
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.street}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            comments
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.completion_comments ? state.details.completion_comments : "No comments attached"}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            AssignedOn
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.assignedOn}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            completedOn
                                        </Table.TextCell>
                                        <Table.TextCell>{formatD(state.details.completedOn)}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                           Tower
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.tower}</Table.TextCell>


                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Sector
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.apn}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Company
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.assigned_company.name}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Technician
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.assigned_tech.name}</Table.TextCell>

                                    </Table.Row>
                                    <Table.Row>
                                        <Table.TextCell>
                                            Technician contact
                                        </Table.TextCell>
                                        <Table.TextCell>{state.details.assigned_tech.contact}</Table.TextCell>

                                    </Table.Row>


                                </Table.Body>
                            </Table>
                        </Pane>

                    </Cd>



                </CardBody>
            </Card>
        </div >
    );
};

const styles = {
    bounce: {
        animation: 'x 2s',
        animationName: Radium.keyframes(zoomIn, 'zoomIn')
    }
}

export default CompletedDetails;
