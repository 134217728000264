import * as Constants from '../utils/constants';

export const startGettingUsers = () => dispatch => {
  dispatch({
    type: Constants.START_APP_USERS,
    payload: 'Start',
  });
};

export const appUsersFail = load => dispatch => {
  dispatch({
    type: Constants.APP_USERS_FAIL,
    payload: load,
  });
};

export const appUsersSuccess = load => dispatch => {
  dispatch({
    type: Constants.APP_USERS_SUCCESS,
    payload: load,
  });
};

export const appUsersReset = () => dispatch => {
  dispatch({
    type: Constants.APP_USERS_RESET,
    payload: 'reset',
  });
};
