import React from "react";
import Apns from "../Highsites";
import Towers from "./tower";
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";

const High = (props) => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Towers />
      </Route>
      <Route path={`${path}/Apns`}>
        <Apns />
      </Route>
    </Switch>
  );
};

export default High;
