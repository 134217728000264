
const initialState = {
    mac: ""
  };
  
  const MacReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_MAC': {
        return {
          ...state,
          mac: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default MacReducer;
  