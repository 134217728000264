import React from 'react';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Pane, Dialog, TextareaField, toaster } from 'evergreen-ui'
import { useMutation, useQuery } from '@apollo/client';
import { Onboard, Accept, rejected } from '../../utils/gql';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

}));


const OnBoard = props => {

    const [isShown, setIsShown] = React.useState(false);
    const [info, setData] = React.useState([]);
    const [comment, setComment] = React.useState("");
    const [row, setRow] = React.useState(null)
    const classes = useStyles();

    const { loading, data } = useQuery(Onboard, { errorPolicy: 'all', fetchPolicy: 'cache-and-network', onError: err => {
        if(Array.isArray(err)){
            err.map((error, index) => {
              toaster.danger(error.message, {
                id: `err_${index}`
              })
            })
          }
          else{
            toaster.danger("Something is wrong with your connection. Please check your internet");
          }
    } })
    const formatDate = date => `${moment(date).format('LL')}`;
    const accept = useMutation(Accept, {errorPolicy: 'all', onCompleted: resp => {
        if(resp.acceptJob != null){
            toaster.success("Job has been approved successfully")
        }
    }, onError: err => {
        if(Array.isArray(err)){
            err.map((error, index) => {
              toaster.danger(error.message, {
                id: `err_${index}`
              })
            })
          }
          else{
            toaster.danger("Something is wrong with your connection. Please check your internet");
          }
    }});

    const rejecte = useMutation(rejected, {errorPolicy: 'all', onCompleted: resp => {
        if(resp.rejectWithComment != null){
            toaster.success("Job rejection done!")
        }
    }, onError: err => {
        if(Array.isArray(err)){
            err.map((error, index) => {
              toaster.danger(error.message, {
                id: `err_${index}`
              })
            })
          }
          else{
            toaster.danger("Something is wrong with your connection. Please check your internet");
          }
    }});
    React.useEffect(() => {
        if (data) {
            if (data.onBoarding != null) {
                let tmp_ = [...data.onBoarding];
               // console.log(tmp_)
                tmp_.sort(dynamicSort("firstName"));
                setData(tmp_);
            }
        }

    }, [loading])

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const handleReject = () => {
        setIsShown(false)
        rejecte[0]({variables: {id: row._id, comment: comment == "" ? null : comment}})
    }

    return (
        <div>
            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite]}>{'On board installations'}</h3>
                </CardHeader>
                <CardBody>
                    <MaterialTable

                        options={{
                            searchFieldStyle: {
                                color: "black",
                                borderBottomColor: "black",
                            },
                            pageSize: 10,
                            pageSizeOptions: [10],
                            search: true,
                            filter: true,
                            actionsColumnIndex: -1
                        }}
                        columns={[
                            {
                                title: "Name", field: "firstName", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                                    )
                                }
                            },
                            {
                                title: "Account", field: "account", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                                    )
                                }
                            },
                            {
                                title: "Assigned On", field: "assignedOn", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{formatDate(row.assignedOn)}</p>
                                    )
                                }
                            },
                            {
                                title: "Location", field: "city", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city ? row.city : " Not assigned"}</p>
                                    )
                                }
                            },
                            
                            {
                                title: "Company", field: "assigned_company.name", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_company ? row.assigned_company.name : " Not provided"}</p>
                                    )
                                }
                            },
                            {
                                title: "assignedTo", field: "assigned_tech.name", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_tech ? row.assigned_tech.name : "Unassigned"}</p>
                                    )
                                }
                            },
                            {
                                title: "Contact", field: "contact", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact ? row.contact : "Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Type", field: "mode", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.mode}</p>
                                    )
                                }
                            }

                        ]}

                        data={info.map(o => ({ ...o }))}
                        onRowClick={(e, RowData) => {
                            //History.push({ pathname: `${url}/companyDetails` })
                        }}

                        actions={[
                            {
                                icon: ThumbUpIcon,
                                tooltip: 'Accept',
                                onClick: (event, rowData) => {
                                    accept[0]({variables: {id: rowData._id}})
                                }
                            },
                            {
                                icon: ThumbDownIcon,
                                tooltip: 'Reject',
                                onClick: (event, rowData) => {
                                    setIsShown(true)
                                    setRow(rowData);
                                }
                            }
                        ]}

                        isLoading={loading || accept[1].loading || rejecte[1].loading}
                        title={""}
                        //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                        components={{
                            OverlayLoading: (props) => (
                                <div
                                    {...props}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <BounceLoader color="#00acc1" size={50} {...props} />
                                </div>
                            ),

                            Toolbar: props => (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                                    <MTableToolbar {...props} />
                                    <div style={{ marginLeft: 10 }}>
                                        <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                                            <TextsmsIcon size="small" />
                                        </Fab>
                                    </div>
                                </div>
                            ),
                        }}
                    />

                    <Pane>
                        <Dialog
                            isShown={isShown}
                            title="Rejection Comment"
                            onCloseComplete={() => setIsShown(false)}
                            onCancel ={() => setIsShown(false)}
                            onConfirm = {handleReject}
                        >
                            <Pane height={200} width="100%" backgroundColor="rgba(149, 193, 210, 0.1)" >
                                <TextareaField
                                    isInvalid={false}
                                    label="Rejection comment"
                                    description="Highlight reason why installation was rejected"
                                    hint="Comment should not exceed 200 characters"
                                    required
                                    value = {comment}
                                    onChange = {e => setComment(e.target.value)}
                                    placeholder="your comment ......."
                                    />
                            </Pane>
                        </Dialog>
                    </Pane>

                </CardBody>
            </Card>

        </div>
    )
}
export default OnBoard;