import * as Constants from '../utils/constants';

export const startSpecific = () => dispatch => {
  dispatch({
    type: Constants.START_SPECIFIC,
    payload: 'Start',
  });
};

export const specificFail = load => dispatch => {
  dispatch({
    type: Constants.SPECIFIC_FAIL,
    payload: load,
  });
};

export const specificSuccess = load => dispatch => {
  dispatch({
    type: Constants.SPECIFIC_SUCCESS,
    payload: load,
  });
};

export const specificReset = () => dispatch => {
  dispatch({
    type: Constants.SPECIFIC_RESET,
    payload: 'reset',
  });
};
