export const setCategory = apn => dispatch => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: apn,
    });
  };

  export const setTechDetails = details => dispatch => {
    dispatch({
      type: 'SET_TECH_DETAILS',
      payload: details
    })
  }

  export const setCompleted = complete => dispatch => {
    dispatch({
      type: 'SET_COMPLETED',
      payload: complete
    })
  }