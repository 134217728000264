
const initialState = {
  Apns: [],
  apn_id: ""
};

const ApnReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APN': {
      return {
        ...state,
        Apns: action.payload
      };
    }

    case 'UPDATE_APN': {
      let tmp = state.Apns.map(element => element._id == action.payload ? Object.assign({}, element, {clients : element.clients + 1}) : element);
        return {
            ...state,
            Apns: tmp
        }
    }
    case 'UPDATE_APN_CLIENTS' : {
      let tmp = state.Apns.map(element => element._id == action.payload ? Object.assign({}, element, {clients : element.clients -1}) : element);
      
      return {
        ...state,
        Apns: tmp
      }
    }

    case 'UPDATE_SWAP_CLIENTS' : {
      let tmp = state.Apns.map(element => element.mac == action.payload.oldMac ? Object.assign({}, element, {clients : element.clients - 1}) : element);
      let tmp_2 = tmp.map(element => element.mac == action.payload.newMac ? Object.assign({}, element, {clients : element.clients + 1}) : element);
      return {
        ...state,
        Apns: tmp_2

      }
    }

    case 'DELETE_FROM_APNS' : {
      console.log(action.payload);
      return {
        ...state,
        Apns: state.Apns.filter(item => item._id !== action.payload)
      }
    }

    case 'MODIFY_APN' : {
      
      let tmp = state.Apns.map(element => element.mac == action.payload.mac ? Object.assign({}, action.payload ) : element);
      return {
        ...state,
        Apns: tmp
      }
    }
    case 'NEW_APN?' : {
      let tmp = [...state.Apns, action.payload]
      return {
        ...state,
        Apns: tmp
      }
    }
    default: {
      return state;
    }
  }
};

export default ApnReducer;
