import React from 'react';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RedoIcon from '@material-ui/icons/Redo';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Pane, toaster, Dialog as Dia, TextInputField } from 'evergreen-ui'
import Select from 'react-dropdown-select'
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useMutation, useQuery } from '@apollo/client';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getActiveClients, fetchCompany, reassigning, createNewJob, EditActive, DeleteActive } from '../../utils/gql';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';

//import Printer, { print } from 'react-pdf-print'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    linear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const InProgress = props => {
    const [isShown, setIsShown] = React.useState(false)
    const [info, setData] = React.useState([]);
    const classes = useStyles();
    const [company, setCompany] = React.useState([]);
    const [add, showAdd] = React.useState(false);
    const [company_id, setID] = React.useState(null);
    const [datam, setInfo] = React.useState(null);
    const [invalidName, setInvalidName] = React.useState(false)
    const [invalidContact, setInvalidContact] = React.useState(false)
    const [invalidEmail, setInvalidEmail] = React.useState(false)
    const [invalidAccount, setInvalidAccount] = React.useState(false)
    const [invalidCity, setInvalidCity] = React.useState(false)
    const [invalidStreet, setInvalidStreet] = React.useState(false)

    const [names, setNames] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [account, setAccount] = React.useState("");
    const [city, setCity] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [mode, setMode] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [createError, setCreateError] = React.useState(false);
    const [err_msg, setErrorMsg] = React.useState("");


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }


    const { loading, data } = useQuery(getActiveClients, { errorPolicy: 'all', fetchPolicy: 'cache-and-network' })
    const deleteActiv = useMutation(DeleteActive, { errorPolicy: 'all' })
    const sign = useMutation(reassigning, {
        errorPolicy: 'all', onError: err => {
            //console.log(err)
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })
            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");
            }
        }, onCompleted: res => {
            if (res.reAssignJob != null) {
                toaster.success("Re-assigning job done")
            }
        }
    })
    const formatDate = date => `${moment(date).format('LL')}`;
    const newJob = useMutation(createNewJob, {errorPolicy: "all", onCompleted: da => {
        if(da.createNewClient != null){
            setID(null);
            setMode(null);
            setCategory(null);
            setNames("");
            setContact("");
            setEmail("");
            setAccount("");
            setCity("");
            setStreet("");
            showAdd(false);
            
            toaster.success("client successfully added")
        }
    }, onError: er => {

        //console.log(er)
        if(Array.isArray(er)){
            setCreateError(true);
            setErrorMsg(er[0].message)
        }
        else{
            setCreateError(true);
            setErrorMsg("Something went wrong")
        }
    }});

    const editActive = useMutation(EditActive, {errorPolicy: "all"})
    const companies = useQuery(fetchCompany, {
        errorPolicy: "all", fetchPolicy: "cache-and-network", onError: err => {
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })
            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");
            }
        },
        onCompleted: data => {
            let tmp_comp = [];
            if (data.getCompany != null) {
                data.getCompany.map(item => {
                    if (item.status == true) {
                        tmp_comp.push({
                            label: item.name,
                            value: item._id
                        })
                    }
                })
                setCompany(tmp_comp);
            }
        }
    })

    React.useEffect(() => {
        if (data) {
            if (data.getActiveClients != null) {
                let tmp_ = [...data.getActiveClients];
                //console.log(tmp_)
                tmp_.sort(dynamicSort("firstName"));
                setData(tmp_);
            }
        }

    }, [loading])

    const handleConfirm = () => {
        if (company_id == null) {
            toaster.danger("Please choose a company to proceed");
            return;
        }

        if (company_id == datam.assigned_company._id) {
            toaster.danger("Can not re-assign job on the same company");
            return;
        }
        setIsShown(false)
        sign[0]({ variables: { id: datam._id, old_comp: datam.assigned_company._id, new_comp: company_id, old_tech: datam.assigned_tech ? datam.assigned_tech._id : null, new_tech: null, state: "active" } })

    }

    const resetAction = () => {
           setID(null);
            setMode(null);
            setCategory(null);
            setNames("");
            setContact("");
            setEmail("");
            setAccount("");
            setCity("");
            setStreet("");
            setInvalidContact(false);
            setInvalidAccount(false);
            setInvalidCity(false);
            setInvalidEmail(false);
            setInvalidStreet(false);
            setInvalidName(false);
            setCreateError(false)
            showAdd(false);
    }

    const handleNewClient = async() => {

         setInvalidContact(false);
         setInvalidAccount(false);
         setInvalidCity(false);
         setInvalidEmail(false);
         setInvalidStreet(false);
         setInvalidName(false);
         setCreateError(false)

        const number = magicNumber(contact);
        const ema = validateEmail(email)

        if(number == false && ema == false && city == "" && street == "" && account == "" && names == ""){
            setInvalidContact(true);
            setInvalidContact(true);
            setInvalidAccount(true);
            setInvalidCity(true);
            setInvalidEmail(true);
            setInvalidStreet(true);
            setInvalidName(true);
            return;
        }

        if(ema == false){
            setInvalidEmail(true)
            return;
        }
        if(number == false){
            setInvalidContact(true)
            return;
        }
        if(city == "" || city.length < 3){
            setInvalidCity(true)
            return;
        }
        if(street == "" || street.length < 3){
            setInvalidStreet(true)
            return;
        }
        if(names == "" || names.length < 5){
            setInvalidName(true)
            return;
        }
        if(account == "" || names.length < 5){
            setInvalidAccount(true)
            return;
        }

        if(names == "" || names.length < 5){
            setInvalidName(true)
            return;
        }

        if(mode == ""){
            toaster.danger("Please choose a mode")
            return;
        }

        if(category == ""){
            toaster.danger("Please choose a category")
            return;
        }

        if(company_id == null){
            toaster.danger("Please choose a company")
            return;
        }

        try {
            const { data, errors } = await newJob[0]({variables: { firstName: names, email, contact, mode, category, company_id, city, street, account}});
            //console.log(errors, "daraaa")

            if(Array.isArray(errors)){
                setCreateError(true);
                setErrorMsg(errors[0].message);
            }
            else{
                setCreateError(true);
                setErrorMsg("Something went wrong")
            }
        }
          catch (e) {
            setCreateError(true);
            setErrorMsg("Something went wrong")
          }
        

        

    }

    const magicNumber = number => {
        let tmp = number.split('');
        if (tmp[0] == "2" && tmp[1] == "5" && tmp[2] == "6" && tmp[3] == "7") {
          if (tmp.length == 12) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      }

      const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
          return true
        }
    
        return false
      }

    return (
        <div>
            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite]}>{'All installations and Troubleshoots in Progress'}</h3>
                </CardHeader>
                <CardBody>


                    <MaterialTable

                        options={{
                            searchFieldStyle: {
                                color: "black",
                                borderBottomColor: "black",
                            },
                            pageSize: 10,
                            pageSizeOptions: [10],
                            search: true,
                            filter: true,
                            actionsColumnIndex: -1
                        }}
                        columns={[
                            {
                                title: "Name", field: "firstName", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                                    )
                                }
                            },
                            {
                                title: "Account", field: "account", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                                    )
                                }
                            },
                            {
                                title: "Assigned On", field: "assignedOn", filtering: true, editable: false, render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{formatDate(row.assignedOn)}</p>
                                    )
                                }
                            },
                            {
                                title: "Location", field: "city", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city ? row.city : " Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Company", field: "assigned_company.name", editable: false, render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_company ? row.assigned_company.name : " Not provided"}</p>
                                    )
                                }
                            },
                            {
                                title: "assignedTo", field: "assigned_tech.name", editable: false, render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_tech ? row.assigned_tech.name : "Unassigned"}</p>
                                    )
                                }
                            },
                            {
                                title: "Contact", field: "contact", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact ? row.contact : "Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Type", field: "mode", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.mode}</p>
                                    )
                                },
                                editComponent: props => (
                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Mode'}
                                        options={[{ label: "troubleshoot", value: 'troubleshoot' }, { label: "installation", value: 'installation' }]}
                                        onChange={values => { props.onChange(values[0].value) }}
                                        style={{
                                            zIndex: 3,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 120,
                                        }}
                                    />
                                )
                            }

                        ]}

                        data={info.map(o => ({ ...o }))}
                        onRowClick={(e, RowData) => {
                            //History.push({ pathname: `${url}/clientDetails` })
                        }}

                        editable={{
                            onRowUpdate: async(newData) =>
                                new Promise(async(resolve) => {
                                    let num = magicNumber(newData.contact)
                                    if(num == false){
                                        toaster.danger("The contact provided is wrong");
                                        resolve();
                                    }
                                    if(newData.firstName == "" || newData.firstName.length < 5){
                                        toaster.danger("first name should be atleast 5 characters");
                                        resolve();
                                    }
                                    if(newData.city == "" || newData.city.length < 3){
                                        toaster.danger("Location should be atleast 3 characters");
                                        resolve();
                                    }

                                    if(newData.mode == ""){
                                        toaster.danger("please choose a right type action");
                                        resolve();
                                    }
                                    const { firstName, account, mode, contact, city, _id} = newData;

                                    try {
                                        const { data, errors} = await editActive[0]({variables: { id: _id, firstName, account, mode, contact, city}});
                            
                                        if(errors && Array.isArray(errors)){
                                            toaster.danger(errors[0].message);
                                            resolve();
                                        }
                                        if(data && data.editActiveClient != null){
                                            resolve();
                                        }
                                    }
                                    catch(err){
                                        //console.log(err)
                                        toaster.danger("Something went wrong");
                                        resolve();
                                    }

                                    
                                }),
                            onRowDelete: oldData =>
                                new Promise(async (resolve) => {

                                   try {
                                    const { data, errors} = await deleteActiv[0]({variables: {id: oldData._id}});

                                    if(data.deleteActiveClient != null){
                                        setData(prev => prev.filter(item => item._id != data.deleteActiveClient._id))
                                        toaster.success("Client successfully deleted");
                                        resolve();
                                    }
                                    if(errors && Array.isArray(errors)){
                                        toaster.danger(errors[0].message);
                                        resolve();
                                    }
                                   }
                                   catch(e){
                                       
                                       toaster.danger("Something wrong happened")
                                       resolve();
                                   }
                                })

                        }}

                        actions={[
                            {
                                icon: RedoIcon,
                                tooltip: 'Assign to Contractors',
                                onClick: (event, rowData) => {
                                    setIsShown(true);
                                    setInfo(rowData);
                                }
                            }
                        ]}

                        localization={{
                            body: {
                                addTooltip: "Fetch more clients"
                            }
                        }}

                        isLoading={loading || companies.loading || sign[1].loading || editActive[1].loading || deleteActiv[1].loading}
                        title={""}

                        components={{
                            OverlayLoading: (props) => (
                                <div
                                    {...props}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <BounceLoader color="#00acc1" size={50} {...props} />
                                </div>
                            ),

                            Toolbar: props => (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                                    <MTableToolbar {...props} />
                                    <div>
                                        <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small" onClick={() => showAdd(true)}>
                                            <AddBoxIcon size="small" />
                                        </Fab>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                                            <TextsmsIcon size="small" />
                                        </Fab>
                                    </div>
                                </div>
                            ),

                        }}
                    />

                    <Pane>

                        <Dia
                            isShown={isShown}
                            title="Re-assign installation"
                            onCloseComplete={() => setIsShown(false)}
                            onCancel={() => setIsShown(false)}
                            onConfirm={handleConfirm}
                        >
                            <Pane height={200} width="100%" >
                                <Pane>
                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Assign to contractors'}
                                        options={company}
                                        onChange={values => { values.length == 0 ? setID("") : setID(values[0].value) }}
                                        style={{
                                            zIndex: 5,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 250
                                        }}
                                    />
                                </Pane>
                            </Pane>
                        </Dia>

                    </Pane>

                    <Pane>

                        <Dialog fullScreen open={add} onClose={() => showAdd(false)} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar} style={{ backgroundColor: createError ? "red": "#00acc1" }}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={resetAction} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Add new installation / troublesoot
                                    </Typography>
                                    <Button autoFocus color="inherit" onClick={handleNewClient}>
                                        save
                                    </Button>
                                </Toolbar>
                            </AppBar>

                            <div className={classes.linear}>
                                {newJob[1].loading && <LinearProgress  style = {{height: 10}}/>}
                                { createError && <p style = {{paddingLeft: 150, color: "red"}}>{err_msg}</p>}
                            </div>
                            <Pane display="flex" justifyContent="space-between" alignItems="center" flex={1} paddingLeft={100} paddingRight={100}>
                                <Pane display="flex" justifyContent="center" alignItems="stretch" flex={1} flexDirection="column">
                                    <TextInputField
                                        label="Names"
                                        isInvalid={invalidName}
                                        description="This is a description."
                                        placeholder="Placeholder text"
                                        value={names}
                                        onChange={e => setNames(e.target.value)}
                                        validationMessage={invalidName ? "names should be greater than 5 characters" : null}
                                    />
                                    <TextInputField
                                        label="email"
                                        isInvalid={invalidEmail}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        description="email details of client"
                                        placeholder="email ......"
                                        validationMessage={invalidEmail ? "wrong email supplied. please use a correct email" : null}
                                    />

                                    <TextInputField
                                        label="contact"
                                        isInvalid={invalidContact}
                                        onChange={e => setContact(e.target.value)}
                                        value={contact}
                                        description="contact details of client"
                                        placeholder="contact ..."
                                        validationMessage={invalidContact ? "contact should be in the format 2567......" : null}
                                    />

                                    <TextInputField
                                        label="account No."
                                        isInvalid={invalidAccount}
                                        onChange={e => setAccount(e.target.value)}
                                        value={account}
                                        description="The account number of client."
                                        placeholder="Account number ...."
                                        validationMessage={invalidAccount ? "Wrong account supplied" : null}
                                    />

                                    <TextInputField
                                        label="city"
                                        isInvalid={invalidCity}
                                        onChange={e => setCity(e.target.value)}
                                        value={city}
                                        description="city details of client"
                                        validationMessage={invalidCity ? "city should be greater than 5 characters" : null}
                                        placeholder="city...."
                                    />
                                    <TextInputField
                                        label="street"
                                        isInvalid={invalidStreet}
                                        onChange={e => setStreet(e.target.value)}
                                        value={street}
                                        description="street details of client"
                                        placeholder="street...."
                                        validationMessage={invalidStreet ? "street should be greater than 5 characters" : null}
                                    />



                                </Pane>

                                <Pane display="flex" justifyContent="space-around" alignItems="center" flex={1} flexDirection="column">

                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Category'}
                                        options={[{ label: "WIRELESS GPON", value: 'WIRELESS GPON' }, { label: "Office Hai", value: 'Hai' }, { label: "DIA client", value: 'DIA' }]}
                                        onChange={values => { setCategory(values[0].value) }}
                                        style={{
                                            zIndex: 5,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 500,
                                            margin: 60
                                        }}
                                    />

                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Mode'}
                                        options={[{ label: "troubleshoot", value: 'troubleshoot' }, { label: "installation", value: 'installation' }]}
                                        onChange={values => { setMode(values[0].value) }}
                                        style={{
                                            zIndex: 3,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 500,
                                            margin: 60
                                        }}
                                    />

                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Contractor'}
                                        options={company}
                                        onChange={values => { setID(values[0].value) }}
                                        style={{
                                            zIndex: 2,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 500,
                                            margin: 60
                                        }}
                                    />





                                </Pane>


                            </Pane>



                        </Dialog>

                    </Pane>


                </CardBody>
            </Card>

        </div>
    )
}
export default InProgress;