/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as BRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import { InMemoryCache, ApolloClient, ApolloProvider } from "@apollo/client";
import configureStore from "./store";
// core components
import Admin from "layouts/Admin.js";
//import { onError } from "@apollo/client/link/error";
import Login from "views/Login/login";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./fonts.css";
import { devUrl, prodUrl } from "./utils/constants";

const hist = createBrowserHistory();
const store = configureStore();

// const errorHandler = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );

//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const client = new ApolloClient({
  //uri: 'http://41.190.153.42:3200/api',
  uri: `${prodUrl}/api`,
  cache: new InMemoryCache(),
});

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

ReactDOM.render(
  <BRouter history={hist}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/home" component={Admin} />

            <Redirect from="/" to="/home/dashboard" exact />
          </Switch>
        </AlertProvider>
      </ApolloProvider>
    </Provider>
  </BRouter>,
  document.getElementById("root")
);
