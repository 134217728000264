import Dashboard from "@material-ui/icons/Dashboard";
import Settings from "@material-ui/icons/Settings";
import Wifi from "@material-ui/icons/Wifi";
import Assignment from "@material-ui/icons/Assignment";
import DashboardPage from "views/Dashboard/Dashboard.js";
import StoreIcon from "@material-ui/icons/Store";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import DesktopMac from "@material-ui/icons/DesktopMac";
import Reports from "views/Reports/Reports";
import Setting from "views/Settings/Settings";
import Ipmanager from "views/IP/Ip";
import Towers from "views/Towers";
import Ftth from "views/FTTH/ftth";
import Pop from "views/Pops/pop";
import Fail from "views/failInstall/fail";
import NewClient from "views/newClients";
import InProgress from "views/InProgress/Inprogress";
import OnBoard from "views/onBoard/onBoard";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import QueueIcon from "@material-ui/icons/Queue";
import Contractor from "views/Companies";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import Completed from "views/Completed";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Churn from "views/Churn/Churn";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample", "sdu_team"],
  },
  {
    path: "/Towers",
    name: "Towers",
    icon: Wifi,
    component: Towers,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },
  {
    path: "/Pops",
    name: "POPs",
    icon: SettingsInputCompositeIcon,
    component: Pop,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },
  {
    path: "/Ftth",
    name: "FTTH",
    icon: StoreIcon,
    component: Ftth,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },
  {
    path: "/IP",
    name: "IP Management",
    icon: DesktopMac,
    component: Ipmanager,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },
  {
    path: "/Reports",
    name: "Reports",
    icon: Assignment,
    component: Reports,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },

  {
    path: "/Churn",
    name: "Inactive",
    icon: DeleteForeverIcon,
    component: Churn,
    layout: "/home",
    roles: ["admin", "liquid_care", "ug_noc", "sample"],
  },
  {
    path: "/Settings",
    name: "Settings",
    icon: Settings,
    component: Setting,
    layout: "/home",
    roles: ["admin", "sample"],
  },
  {
    path: "/NewClients",
    name: "NewClients",
    icon: PersonAddIcon,
    component: NewClient,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
  {
    path: "/InProgress",
    name: "Active Installations",
    icon: AssignmentReturnIcon,
    component: InProgress,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
  {
    path: "/Completed",
    name: "Completed Installations",
    icon: AssignmentTurnedInIcon,
    component: Completed,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
  {
    path: "/OnBoard",
    name: "OnBoard clients",
    icon: QueueIcon,
    component: OnBoard,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
  {
    path: "/Contractor",
    name: "Contractors",
    icon: BusinessIcon,
    component: Contractor,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
  {
    path: "/Failed",
    name: "Failed Installations",
    icon: CancelIcon,
    component: Fail,
    layout: "/home",
    roles: ["admin", "sample", "sdu_team"],
  },
];

export default dashboardRoutes;
