import React from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector } from 'react-redux';
import { createUser } from '../../utils/gql';
import { useMutation } from '@apollo/client';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { PieChart } from 'react-minimal-pie-chart';
import {
  Pane,
  Card as Cards,
  toaster,
  Table
} from "evergreen-ui";
import moment from "moment";

const useStyles = makeStyles(styles);

const Details = () => {

  const [active, setActive] = React.useState([])
  const [failed, setFail] = React.useState([])
  const [completed, setComplete] = React.useState([])

  const state = useSelector(state => ({
    tech: state.category.tech,
    company: state.category.company.name

  }))

  React.useEffect(() => {
    setActive(state.tech.active)
    setFail(state.tech.failed)
    setComplete(state.tech.completed)

  }, [])

  const defaultLabelStyle = {
    fontSize: '2px',
    fontFamily: 'sans-serif',
    textColor: 'white'
  };

  const classes = useStyles();
  return (
    <div style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
      <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
        <CardHeader color="info">
          <h3 className={[classes.cardTitleWhite, styles.bounce]}>{state.tech.name}</h3>
          <h4 className={[classes.cardTitleWhite, styles.bounce]}>{state.company}</h4>
        </CardHeader>
      <CardBody>

        <Cards display="flex" justifyContent="center" alignItems="center">

          <PieChart
            animate={true}
            animationDuration={1000}
            totalValue={completed.length + active.length + failed.length}
            radius={35}
            cursor="help"
            className={styles.bounce}
            segmentsShift={() => 0}
            data={[
              { title: 'Completed', value: completed.length, color: '#a8ff9e' },
              { title: 'Pending', value: active.length, color: '#fff3a6' },
              { title: 'Failed', value: failed.length, color: '#f72a23' },
            ]}
            onClick={() => { }}
            onMouseOver={() => {

            }}
            label={({ dataEntry }) => dataEntry.percentage > 0 ? dataEntry.title : null}
            labelStyle={{
              ...defaultLabelStyle,
            }}

          />
          <Pane display="flex" justifyContent="center" alignItems="center">
            <Table style={{ marginTop: '35%' }}>
              <Table.Head>
                <Table.TextHeaderCell style={{ backgroundColor: '#a8ff9e' }}>Completed</Table.TextHeaderCell>
                <Table.TextHeaderCell style={{ backgroundColor: '#fff3a6' }}>Pending</Table.TextHeaderCell>
                <Table.TextHeaderCell style={{ backgroundColor: '#f72a23' }}>Failed</Table.TextHeaderCell>
                <Table.TextHeaderCell>Total</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={240}>
               
                  <Table.Row key={1}>
                    <Table.Cell style = {{justifyContent: "center", alignItems: "center"}}>{completed.length}</Table.Cell>
                    <Table.Cell style = {{justifyContent: "center", alignItems: "center"}}>{active.length}</Table.Cell>
                    <Table.Cell style = {{justifyContent: "center", alignItems: "center"}}>{failed.length}</Table.Cell>
                    <Table.Cell style = {{justifyContent: "center", alignItems: "center"}}>{completed.length + active.length + failed.length}</Table.Cell>
                  </Table.Row>
      
              </Table.Body>
            </Table>

            <List className={classes.root} style={{ top: "25%" }}>
              <ListItem role={undefined} dense button onClick={() => { }}>
                <ListItemIcon>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary={moment(state.tech.date).format("MMM Do YY")} />
              </ListItem>
              <ListItem role={undefined} dense button onClick={() => { }}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={state.tech.name} />
              </ListItem>
              <ListItem role={undefined} dense button onClick={() => { }}>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={state.tech.email} />
              </ListItem>
              <ListItem role={undefined} dense button onClick={() => { }}>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={state.tech.role} />
              </ListItem>


              <ListItem role={undefined} dense button onClick={() => { }}>
                <ListItemIcon>
                  <ContactPhoneIcon />
                </ListItemIcon>
                <ListItemText primary={state.tech.contact} />
              </ListItem>
              
            </List>

          </Pane>


        </Cards>
      </CardBody>
      </Card>
    </div >
  );
};
export default Details;
