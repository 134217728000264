/* eslint-disable react/display-name */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import Button from "../../components/CustomButtons/Button";
import BounceLoader2 from "react-spinners/SyncLoader";
import Fab from "@material-ui/core/Fab";
import TextsmsIcon from "@material-ui/icons/Textsms";
import BounceLoader from "react-spinners/MoonLoader";
import { useMutation, useQuery } from "@apollo/client";
import { theActions, sendToAll } from "../../utils/gql";
import {
  SideSheet,
  Paragraph,
  Heading,
  Pane,
  Card as Cards,
  Position,
  Textarea,
  toaster,
} from "evergreen-ui";
import { useSelector } from "react-redux";
import { formatDate } from "utils/constants";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  container4: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
};

const useStyles = makeStyles(styles);

const Reports = () => {
  const classes = useStyles();

  const [info, setInfo] = React.useState([]);
  const state = useSelector((state) => ({ auth: state.auth }));
  const [message, setMessage] = React.useState("");
  const [err, setError] = React.useState(false);
  const [openText, setText] = React.useState(false);

  const send_msg = useMutation(sendToAll, { errorPolicy: "all" });

  const { errors, loading, data } = useQuery(
    theActions,
    { variables: { user: state.auth.username } },
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        console.log(err);
        if (Array.isArray(err)) {
          err.map((error, index) => {
            toaster.danger(error.message, {
              id: `err_${index}`,
            });
          });
        } else {
          toaster.danger(
            "Something is wrong with your connection. Please check your internet"
          );
        }
      },
    }
  );

  const handleSmsChange = (event) => {
    const text = event.target.value;
    if (text.length <= 160) {
      setError(false);
      setMessage(text);
    } else {
      setError(true);
    }
  };

  const handleMessage = async () => {
    if (message == "" || message.length < 20) {
      toaster.danger("Message should be atleast 20 characters in length");
      return;
    } else {
      try {
        const { errors, data } = await send_msg[0]({
          variables: { message, user: state.auth.username },
        });
        if (errors && Array.isArray(errors)) {
          toaster.danger(errors[0].message);
        }

        if (data != null) {
          const tmp = JSON.parse(data?.notifyAll.message);
          if (tmp.isError == true) {
            toaster.danger(tmp.message);
          } else {
            toaster.success(
              "The SMS message has been successfully shared with the clients"
            );
            setMessage("");
          }
        }
      } catch (e) {
        toaster.danger("Something went wrong");
      }
    }
  };

  const truncateString = (str) => {
    if (str.length <= 50) {
      return str;
    } else {
      return str.substring(0, 50) + "...";
    }
  };

  const triggerOpener = () => {
    if (
      state?.auth?.username === "amanda.kaboggoza@liquid.tech" ||
      state?.auth?.username === "agnes.ikiyai@liquid.tech"
    ) {
      setText(true);
    } else {
      toaster.danger("You don't have permission to execute command");
    }
  };

  React.useEffect(() => {
    if (data && data?.getActions) {
      setInfo(data?.getActions);
    }
    if (errors) {
      toaster.danger(errors[0]?.message);
    }
  }, [loading, data, errors]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>
              Liquid Intelligent Technologies
            </h4>
            <p className={classes.cardCategoryWhite}>SMS Messages</p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              options={{
                searchFieldStyle: {
                  color: "black",
                  borderBottomColor: "black",
                },
                pageSize: 10,
                pageSizeOptions: [10],
                actionsColumnIndex: -1,
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData?.newClients > 0 ? "#52b8eb" : "#FFFFFF",
                }),
              }}
              columns={[
                {
                  title: "Message",
                  field: "message",
                  render: (row) => {
                    return <p>{truncateString(row?.parameters?.message)}</p>;
                  },
                },
                {
                  title: "Sent to",
                  field: "user",
                  render: (row) => {
                    return <p>{row?.parameters?.user}</p>;
                  },
                },
                {
                  title: "Executed By",
                  field: "executedBy",
                },

                {
                  title: "Date",
                  field: "category",
                  render: (dara) => <p>{formatDate(Number(dara?.date))}</p>,
                },
              ]}
              data={info?.map((o) => ({ ...o }))}
              onRowClick={(e, RowData) => {
                //dispatch(setHighsite(RowData.name));
                //dispatch(setApnId())
              }}
              isLoading={loading}
              title={""}
              //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
              components={{
                OverlayLoading: (props) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <BounceLoader color="#00acc1" size={50} {...props} />
                  </div>
                ),

                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <MTableToolbar {...props} />
                    <div>
                      <Fab
                        aria-label="add"
                        style={{ color: "white", backgroundColor: "#00acc1" }}
                        size="small"
                        onClick={triggerOpener}
                      >
                        <TextsmsIcon size="small" />
                      </Fab>
                    </div>
                  </div>
                ),
              }}
              localization={{
                body: {
                  addTooltip: "Add new Access Point",
                },
              }}
              detailPanel={(rowData) => {
                return (
                  <div>
                    <p className="centered">{rowData?.parameters?.message}</p>
                  </div>
                );
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <React.Fragment>
        <SideSheet
          isShown={openText}
          onCloseComplete={() => {
            setText(false);
          }}
          position={Position.LEFT}
          style={{ zIndex: 20 }}
        >
          <Pane
            zIndex={10}
            flexShrink={0}
            elevation={0}
            backgroundColor="white"
          >
            <Pane padding={16}>
              <Heading size={600}>Message clients</Heading>
              <Paragraph size={400}>Send SMS to all RF clients</Paragraph>
            </Pane>
          </Pane>
          <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
            <Cards
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            ></Cards>
            <Cards
              backgroundColor="white"
              elevation={0}
              flexDirection="column"
              padding={10}
              display="flex"
            >
              <Pane>
                <>
                  <Textarea
                    id="textarea-3"
                    width={350}
                    height={400}
                    placeholder="Your sms to clientsxxx...."
                    value={message}
                    onChange={handleSmsChange}
                    style={{ backgroundColor: err ? "red" : "white" }}
                  />
                  <Cards display="flex" flexDirection="row" width={350}>
                    <Button color="info" rounded block onClick={handleMessage}>
                      {send_msg[1].loading ? (
                        <BounceLoader2 color="white" />
                      ) : (
                        <p>Send</p>
                      )}
                    </Button>
                  </Cards>
                </>
              </Pane>
            </Cards>
          </Pane>
        </SideSheet>
      </React.Fragment>
    </GridContainer>
  );
};

export default Reports;
