import React from 'react';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Pane, Dialog, TextareaField, toaster } from 'evergreen-ui'
import { onCompleted } from '../../utils/gql';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { setCompleted } from '../../actions/_setCategory';
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import update from 'immutability-helper';




//import Printer, { print } from 'react-pdf-print'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

}));


const Completed = () => {
    const { url } = useRouteMatch();
    const History = useHistory();
    const classes = useStyles();
    const [info, setData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(new Date());
    const dispatch = useDispatch();
    const { loading, data } = useQuery(onCompleted, {
        errorPolicy: 'all', fetchPolicy: 'cache-and-network', onError: err => {
            if (Array.isArray(err)) {
                err.map((error, index) => {
                    toaster.danger(error.message, {
                        id: `err_${index}`
                    })
                })
            }
            else {
                toaster.danger("Something is wrong with your connection. Please check your internet");
            }
        }
    })
    const formatDate = date => `${moment(date).format('LL')}`;

    React.useEffect(() => {
        if (data) {
            if (data.getCompleted != null) {
                let tmp_data = [];
                let tmp_ = [...data.getCompleted].map(item => {
                    let temp = {};
                   
                    temp = update(item, {
                        assignedOn: {$set: formatDate(item.assignedOn)}
                    })
                    tmp_data.push(temp);
                });
                tmp_data.sort(dynamicSort("firstName"));
                setData(tmp_data);
            }
        }

    }, [loading])

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <div>
            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite]}>{'Completed Installations'}</h3>
                </CardHeader>
                <CardBody>
                    <MaterialTable

                        options={{
                            searchFieldStyle: {
                                color: "black",
                                borderBottomColor: "black",
                            },
                            pageSize: 10,
                            pageSizeOptions: [10],
                            search: true,
                            filter: true,
                            actionsColumnIndex: -1
                        }}
                        columns={[
                            {
                                title: "Name", field: "firstName", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                                    )
                                }
                            },
                            {
                                title: "Account", field: "account", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                                    )
                                }
                            },
                            {
                                title: "Assigned On", field: "assignedOn", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assignedOn}</p>
                                    )
                                }
                            },
                            {
                                title: "Location", field: "city", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city ? row.city : " Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Company", field: "assigned_company.name", filtering: true, render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_company ? row.assigned_company.name : " Not provided"}</p>
                                    )
                                }
                            },
                            {
                                title: "assignedTo", field: "assigned_tech.name", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_tech ? row.assigned_tech.name : "Unassigned"}</p>
                                    )
                                }
                            },
                            {
                                title: "Contact", field: "contact", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact ? row.contact : "Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Type", field: "mode", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.mode}</p>
                                    )
                                }
                            }

                        ]}

                        data={info.map(o => ({ ...o }))}
                        onRowClick={(e, RowData) => {
                            dispatch(setCompleted(RowData))

                            History.push({ pathname: `${url}/completedDetails` })
                        }}



                        isLoading={loading}
                        title={""}
                        //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                        components={{
                            OverlayLoading: (props) => (
                                <div
                                    {...props}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <BounceLoader color="#00acc1" size={50} {...props} />
                                </div>
                            ),

                            Toolbar: props => (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                                    <MTableToolbar {...props} />
                                    <div style={{ marginLeft: 10 }}>
                                        <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                                            <TextsmsIcon size="small" />
                                        </Fab>
                                    </div>

                                    <div style={{ marginLeft: 10, zIndex: 15 }}>
                                        <DatePicker selected={startDate} onChange={(date) => {
                                            console.log(date)
                                            setStartDate(date)
                                        }} />
                                    </div>
                                </div>
                            ),
                        }}
                    />

                </CardBody>
            </Card>

        </div>
    )
}
export default Completed;