/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import { useMutation, useQuery } from "@apollo/client";
import { oldClients } from "utils/gql";
import { toaster } from "evergreen-ui";
import { formatDate } from "utils/constants";
import { removeInactive } from "utils/gql";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  container4: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
};

const useStyles = makeStyles(styles);

const Churn = (props) => {
  const [info, setInfo] = React.useState([]);
  const [deleting, setDeleting] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const classes = useStyles();
  const tableRef = React.useRef(null);

  const { errors, loading, data } = useQuery(oldClients, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const deleteUsers = useMutation(removeInactive, { errorPolicy: "all" });
  let ids = [];

  const handleDelete = async () => {
    //console.log(ids);
    setDeleting(true);
    await deleteUsers[0]({ variables: { list: ids } })
      .then((res) => {
        if (res?.data?.deleteInactiveUser) {
          setDeleting(false);
          ids = [];
          toaster.success("User deleted from system");
        } else {
          setDeleting(false);
          toaster.danger("Operation unsuccessful");
        }
      })
      .catch((err) => {
        toaster.danger("Error while deleting user");
        setDeleting(false);
      });
  };

  React.useEffect(() => {
    if (data && data?.getOldUsers) {
      setInfo(data?.getOldUsers);
    }
    if (errors) {
      toaster.danger(errors[0]?.message);
    }
  }, [loading, data, errors]);

  React.useEffect(() => {
    console.log("Selected Rows:", selectedRows);
  }, [selectedRows]);

  const handleDeleteSelectedRows = () => {
    if (tableRef.current) {
      //console.log(tableRef.current);
      const selectedRows = tableRef.current.dataManager.data;
      let modified = selectedRows
        .filter((item) => item?.tableData?.checked === true)
        .map((i) => i?._id);
      //setSelectedRows(modified);
      ids = modified;
      // Perform your delete operation or any other action with the selected rows here
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>
              Liquid Intelligent Technologies
            </h4>
            <p className={classes.cardCategoryWhite}>Inactive clients</p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              tableRef={tableRef}
              options={{
                searchFieldStyle: {
                  color: "black",
                  borderBottomColor: "black",
                },
                selection: true,
                pageSize: 10,
                pageSizeOptions: [10],
                actionsColumnIndex: -1,
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData?.newClients > 0 ? "#52b8eb" : "#FFFFFF",
                }),
              }}
              columns={[
                {
                  title: "First Name",
                  field: "firstName",
                },
                {
                  title: "Contact",
                  field: "contact",
                },
                {
                  title: "Management",
                  field: "managementIP",
                },
                {
                  title: "Tower",
                  field: "tower",
                },

                {
                  title: "CreatedAt",
                  field: "category",
                  render: (data) => {
                    return <p>{formatDate(Number(data?.createdAt))}</p>;
                  },
                },
              ]}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    deleteUsers[0]({
                      variables: { list: [oldData?._id] },
                    })
                      .then((res) => {
                        if (res?.data?.deleteInactiveUser) {
                          toaster.success("User deleted from system");
                          setInfo((old) =>
                            old.filter(
                              (item) =>
                                item?._id !== res?.data?.deleteInactiveUser?._id
                            )
                          );
                          resolve();
                        } else {
                          toaster.danger("Operation unsuccessful");
                          resolve();
                        }
                      })
                      .catch((err) => {
                        toaster.danger("Error while deleting user");
                        resolve();
                      });
                  }),
              }}
              data={info?.map((o) => ({ ...o }))}
              onRowClick={(e, RowData) => {
                //dispatch(setHighsite(RowData.name));
                //dispatch(setApnId())
              }}
              onSelectionChange={handleDeleteSelectedRows}
              isLoading={loading || deleteUsers[1]?.loading || deleting}
              title={""}
              //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
              components={{
                OverlayLoading: (props) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <BounceLoader color="#00acc1" size={50} {...props} />
                  </div>
                ),

                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <MTableToolbar {...props} />

                    <DeleteForeverIcon
                      style={{ color: "red", fontSize: 30 }}
                      onClick={handleDelete}
                    />
                  </div>
                ),
              }}
              localization={{
                body: {
                  addTooltip: "Inactive clients",
                },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Churn;
