
const initialState = {
  name: "",
  tower: "",
  towers: []
};

const NameReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NAME': {
      return {
        ...state,
        name: action.payload,
      };
    }
    case 'SET_TOWER': {
      return {
        ...state,
        tower: action.payload
      }
    }
    case 'STORE_TOWERS': {
      return {
        ...state,
        towers: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export default NameReducer;
