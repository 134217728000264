
const initialState = {
    sectorID: "",
    sector: ""
  };
  
  const SectorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SECTOR': {
        return {
          ...state,
          sectorID: action.payload.id,
          sector: action.payload.sector
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default SectorReducer;
  