import * as Constants from '../utils/constants';

export const setRole = token => dispatch => {
  dispatch({
    type: 'SET_ROLE',
    payload: token,
  });
};

export const setUsername = load => dispatch => {
  dispatch({
    type: 'SET_USERNAME',
    payload: load,
  });
};

export const loginx =()=> dispatch => {
  dispatch({
    type: Constants.ISLOGGEDIN,
    payload: 'LOGIN',
  });
};

export const logOut = () => dispatch => {
  dispatch({
    type: Constants.LOGOUT,
    payload: 'LOGOUT',
  });
};