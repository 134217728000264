
const initialState = {
    detail: ""
  };
  
  const DetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_DETAIL': {
        return {
          ...state,
          name: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default DetailReducer;
  