import { gql } from "@apollo/client";

export const getHighsites = gql`
  query Fetch($count: Int!, $offset: String) {
    highsites(count: $count, offset: $offset) {
      _id
      name
      ip
    }
    info(count: $count, offset: $offset) {
      total
      cursor
    }
  }
`;

export const theActions = gql`
  query GetActions($user: String) {
    getActions(user: $user) {
      executedBy
      date
      action
      parameters {
        user
        message
      }
    }
  }
`;

export const addHighsites = gql`
  mutation AddHighsite($name: String!, $ip: String!) {
    createHighSite(name: $name, ip: $ip) {
      _id
      name
      ip
    }
  }
`;

export const deleteApns = gql`
  mutation DeleteApn($id: ID!, $mac: String!) {
    deleteApn(id: $id, mac: $mac) {
      _id
    }
  }
`;

export const getSectors = gql`
  query GetSectors($id: ID!) {
    sectors(id: $id) {
      _id
      name
      ip
      clients
      highsiteID
    }
  }
`;

export const createSector = gql`
  mutation CreateSector(
    $name: String!
    $ip: String!
    $clients: Int
    $highsiteID: String!
    $mac: String
  ) {
    createSector(
      name: $name
      ip: $ip
      clients: $clients
      highsiteID: $highsiteID
      mac: $mac
    ) {
      _id
      name
      highsiteID
      clients
      ip
      mac
    }
  }
`;

export const editUser = gql`
  mutation EditUser(
    $id: ID!
    $name: String!
    $contact: String!
    $email: String!
  ) {
    editUser(id: $id, name: $name, contact: $contact, email: $email) {
      _id
      firstName
      managementIP
      email
      contact
      newClient
    }
  }
`;

export const deleteUser = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      _id
    }
  }
`;

export const createUser = gql`
  mutation CreateUser(
    $tower: String!
    $apn: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $contact: String!
    $vlanID: String!
    $category: String!
    $state: Boolean!
    $account: String!
  ) {
    checkUser(
      tower: $tower
      apn: $apn
      firstName: $firstName
      lastName: $lastName
      email: $email
      contact: $contact
      vlanID: $vlanID
      category: $category
      state: $state
      account: $account
    ) {
      tower
      apn
      firstName
      lastName
      email
      contact
      vlanID
      managementIP
      category
      state
      account
      _id
    }
  }
`;

export const makeTowers = gql`
  mutation CreateTower($name: String!) {
    createTower(name: $name) {
      message
    }
  }
`;
export const queryTowers = gql`
  query {
    getTowers {
      _id
      tower
      netBlock {
        block
      }
      apns
      current
      newClients
    }
  }
`;

export const oldClients = gql`
  query {
    getOldUsers {
      _id
      firstName
      lastName
      managementIP
      tower
      contact
      createdAt
    }
  }
`;

export const queryApns = gql`
  query GetApns($name: String!) {
    getApns(name: $name) {
      _id
      name
      ip
      clients
      mac
      category
      newClients
    }
  }
`;
export const queryUsers = gql`
  query Users($mac: String!) {
    users(mac: $mac) {
      _id
      firstName
      managementIP
      contact
      email
      newClient
    }
  }
`;

export const swapUser = gql`
  mutation SwapClient(
    $id: ID!
    $tower: String!
    $newMac: String!
    $oldMac: String!
  ) {
    swapClient(newMac: $newMac, oldMac: $oldMac, id: $id, tower: $tower) {
      _id
      firstName
      managementIP
      contact
      email
      apn
      tower
    }
  }
`;

export const removeInactive = gql`
  mutation DeleteInactiveUser($list: [ID!]!) {
    deleteInactiveUser(list: $list) {
      _id
      firstName
      managementIP
      contact
      email
    }
  }
`;
export const getUsable = gql`
  query {
    sortIps {
      _id
      ip
    }
  }
`;

export const createNewApn = gql`
  mutation CreateNewApn(
    $name: String!
    $mac: String!
    $category: String!
    $tower: String!
    $clients: Int
  ) {
    createApn(
      name: $name
      mac: $mac
      category: $category
      tower: $tower
      clients: $clients
    ) {
      _id
      name
      mac
      clients
      category
      ip
      tower
    }
  }
`;

export const updateApns = gql`
  mutation UpdateApn($id: ID!, $name: String!, $category: String!) {
    updateApn(id: $id, name: $name, category: $category) {
      _id
      name
      mac
      clients
      category
      ip
      tower
    }
  }
`;

export const validateUsers = gql`
  query CheckUsers($username: String!, $password: String!) {
    checkUsers(username: $username, password: $password) {
      _id
      username
      role
    }
  }
`;

export const newTower = gql`
  mutation CreateNewTower($tower: String!) {
    createNewTower(tower: $tower) {
      _id
      tower
      netBlock {
        block
      }
      apns
      current
    }
  }
`;

export const towerEditor = gql`
  mutation EditTower($id: ID!, $tower: String!) {
    editTower(id: $id, tower: $tower) {
      _id
      tower
    }
  }
`;

export const towerDel = gql`
  mutation DeleteTower($id: ID!) {
    deleteTower(id: $id) {
      _id
      tower
    }
  }
`;

export const searchAll = gql`
  query {
    getAll {
      firstName
      managementIP
      tower
      apn
      access {
        name
      }
    }
  }
`;

export const getNewClients = gql`
  query {
    getNewClients {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
      category
    }
  }
`;

export const fetchMore = gql`
  query {
    newClients {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
    }
  }
`;

export const fetchCompany = gql`
  query {
    getCompany {
      _id
      contact
      email
      date
      number
      name
      status
      location
    }
  }
`;

export const createCompany = gql`
  mutation CreateCompany(
    $name: String!
    $email: String!
    $contact: String!
    $location: String!
    $date: String!
    $number: Int!
    $status: Boolean!
  ) {
    createCompany(
      name: $name
      email: $email
      contact: $contact
      location: $location
      date: $date
      number: $number
      status: $status
    ) {
      _id
      name
      contact
      location
      number
      date
      email
      status
    }
  }
`;

export const editCompany = gql`
  mutation EditCompany(
    $id: ID!
    $name: String!
    $email: String!
    $contact: String!
    $location: String!
    $status: Boolean!
  ) {
    editCompany(
      id: $id
      name: $name
      email: $email
      contact: $contact
      location: $location
      status: $status
    ) {
      _id
      name
      contact
      location
      number
      date
      email
      status
    }
  }
`;

export const assignNewClient = gql`
  mutation AssignNewClient($client: String!) {
    assignNewClient(client: $client) {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
    }
  }
`;

export const editNewClient = gql`
  mutation EditNewClient(
    $id: ID!
    $firstName: String!
    $contact: String!
    $street: String!
    $city: String!
  ) {
    editNewClient(
      id: $id
      firstName: $firstName
      contact: $contact
      street: $street
      city: $city
    ) {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
    }
  }
`;

export const getTechs = gql`
  query GetTechs($id: ID!) {
    getTech(id: $id) {
      _id
      name
      contact
      role
      date
      email
      company {
        _id
        name
      }
      status
    }
  }
`;
export const createtech = gql`
  mutation CreateTech(
    $name: String!
    $company: ID!
    $email: String!
    $contact: String!
    $date: String!
    $status: Boolean!
    $role: String!
  ) {
    createTech(
      company: $company
      name: $name
      contact: $contact
      email: $email
      role: $role
      date: $date
      status: $status
    ) {
      _id
      name
      role
      email
      contact
      date
      company {
        _id
        name
      }
      status
    }
  }
`;

export const edittech = gql`
  mutation EditTech(
    $name: String!
    $id: ID!
    $email: String!
    $contact: String!
    $status: Boolean!
    $role: String!
  ) {
    editTech(
      id: $id
      name: $name
      contact: $contact
      email: $email
      role: $role
      status: $status
    ) {
      _id
      name
      role
      email
      contact
      date
      company {
        _id
        name
      }
      status
    }
  }
`;

export const deletetech = gql`
  mutation DeleteTech($id: ID!, $company: ID!) {
    deleteTech(id: $id, company: $company) {
      _id
      name
      role
      email
    }
  }
`;

export const getByComp = gql`
  query GetByComp($id: ID!) {
    getByCompany(id: $id) {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
      assigned_tech {
        name
        _id
      }
      mode
      state
    }
  }
`;

export const assignto = gql`
  mutation Assignto($id: ID!, $tech_id: ID!) {
    assignToTech(id: $id, tech_id: $tech_id) {
      _id
      contact
      email
      city
      street
      date
      firstName
      account
      assigned_tech {
        name
        _id
      }
      mode
      state
    }
  }
`;

export const gettechbyid = gql`
  mutation GetTechByID($id: ID!) {
    getTechByID(id: $id) {
      _id
      name
      role
      email
      contact
      date
      status
      completed {
        _id
        contact
        email
        city
        street
        date
        firstName
        account
      }
      active {
        _id
        contact
        email
        city
        street
        date
        firstName
        account
      }
      failed {
        _id
        contact
        email
        city
        street
        date
        firstName
        account
      }
    }
  }
`;

export const getActiveClients = gql`
  query {
    getActiveClients {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const reassigning = gql`
  mutation ReAssign(
    $id: ID!
    $new_comp: ID
    $old_comp: ID
    $new_tech: ID
    $old_tech: ID
    $state: String
  ) {
    reAssignJob(
      id: $id
      new_comp: $new_comp
      old_comp: $old_comp
      new_tech: $new_tech
      old_tech: $old_tech
      state: $state
    ) {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const fetchFailed = gql`
  query {
    getFailed {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const Onboard = gql`
  query {
    onBoarding {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      assignedOn
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const Stats = gql`
  query {
    dashboard {
      towerCount
      apCount
      userStats {
        active
        inactive
        new
        total
      }
    }
  }
`;

export const Accept = gql`
  mutation AcceptJob($id: ID!) {
    acceptJob(id: $id) {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      assignedOn
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const rejected = gql`
  mutation Rejected($id: ID!, $comment: String) {
    rejectWithComment(id: $id, comment: $comment) {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      assignedOn
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const onCompleted = gql`
  query {
    getCompleted {
      _id
      contact
      email
      city
      street
      date
      firstName
      mode
      assignedOn
      completion_comment
      account
      category
      tower
      apn
      completedOn
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
        contact
      }
    }
  }
`;

export const createNewJob = gql`
  mutation CreateNew(
    $firstName: String!
    $email: String!
    $contact: String!
    $category: String!
    $account: String!
    $city: String!
    $street: String!
    $company_id: ID!
    $mode: String!
  ) {
    createNewClient(
      firstName: $firstName
      email: $email
      contact: $contact
      category: $category
      account: $account
      city: $city
      street: $street
      company_id: $company_id
      mode: $mode
    ) {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      assignedOn
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const EditActive = gql`
  mutation editActive(
    $id: ID!
    $firstName: String!
    $contact: String!
    $city: String!
    $mode: String!
    $account: String!
  ) {
    editActiveClient(
      id: $id
      firstName: $firstName
      contact: $contact
      city: $city
      mode: $mode
      account: $account
    ) {
      _id
      contact
      email
      city
      street
      date
      firstName
      assignedOn
      mode
      assignedOn
      account
      category
      assigned_company {
        _id
        name
      }
      assigned_tech {
        _id
        name
      }
    }
  }
`;

export const DeleteActive = gql`
  mutation deleteActiv($id: ID!) {
    deleteActiveClient(id: $id) {
      _id
    }
  }
`;

export const sendApMessage = gql`
  mutation SendApMessage(
    $tower: String!
    $access_point: String!
    $message: String!
    $subject: String!
    $type: String!
    $user: String!
  ) {
    createApMessage(
      tower: $tower
      access_point: $access_point
      message: $message
      subject: $subject
      type: $type
      user: $user
    ) {
      message
    }
  }
`;

export const sendToHighsite = gql`
  mutation NotifySite($tower: String!, $message: String!, $user: String!) {
    notifySite(tower: $tower, message: $message, user: $user) {
      message
    }
  }
`;

export const mailAp = gql`
  mutation MailSite(
    $tower: String!
    $message: String!
    $subject: String!
    $type: String!
  ) {
    mailSite(tower: $tower, message: $message, subject: $subject, type: $type) {
      message
    }
  }
`;

export const mailAllClients = gql`
  mutation MailAllClients(
    $message: String!
    $subject: String!
    $type: String!
  ) {
    mailAll(message: $message, subject: $subject, type: $type) {
      message
    }
  }
`;

export const sendToAll = gql`
  mutation NotifyAll($message: String!, $user: String!) {
    notifyAll(message: $message, user: $user) {
      message
    }
  }
`;
