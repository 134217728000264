import React from "react";
import CompanyDetails from "./companyDetails";
import Details from "./Details";
import {
    useRouteMatch,
    Switch,
    Route,
} from "react-router-dom";

const TechInfo = (props) => {

    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <CompanyDetails />
            </Route>
            <Route path={`${path}/techinfo`}>
                <Details />
            </Route>
        </Switch>
    );
};

export default TechInfo;
