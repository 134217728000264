
export const setApn = apn => dispatch => {
    dispatch({
      type: 'SET_APN',
      payload: apn,
    });
  };


export const update_Apn = apn => dispatch => {
  dispatch({
    type: 'UPDATE_APN',
    payload: apn,
  });
};

export const updateClients = id => dispatch => {
  dispatch({
    type: 'UPDATE_APN_CLIENTS',
    payload: id
  })
}

export const updateSwapClients = list => dispatch => {
  dispatch({
    type: 'UPDATE_SWAP_CLIENTS',
    payload: list
  })
}

export const delete_apn = apn => dispatch => {
  dispatch({
    type: 'DELETE_FROM_APNS',
    payload: apn
  })
}

export const modify_apn = apn => dispatch => {
  dispatch({
    type: 'MODIFY_APN',
    payload: apn
  })
}

export const create_new_apn = apn => dispatch => {
  dispatch({
    type: 'NEW_APN?',
    payload: apn
  })
}