import moment from "moment";

export const START_LOGIN = "START LOGIN PROCESS";
export const LOGIN_FAIL = "LOGIN FAILED";
export const LOGIN_SUCCESS = "LOGIN SUCCESSFUL";
export const LOGIN_RESET = "RESET LOGIN PROCESS";

export const START_APP_USERS = "START GETTING APP USERS";
export const APP_USERS_FAIL = "FAILED FETCHING APP USERS";
export const APP_USERS_SUCCESS = "APP USERS SUCCESS";
export const APP_USERS_RESET = "RESET_GETTING APP USERS";

export const devUrl = "http://localhost:3200";
export const prodUrl = "https://commportal.liquidtelecom.co.ug/api";

export const SETTOKEN = "SET USER TOKEN";
export const SETEMAIL = "SET USER EMAIL";
export const ISLOGGEDIN = "ENABLE LOGIN";
export const LOGOUT = "LOG USER OUT";

export const START_SPECIFIC = "START GETTING SPECIFIC USER";
export const SPECIFIC_SUCCESS = "SPECIFIC USER SUCCESS";
export const SPECIFIC_RESET = "SPECIFIC USER RESET";
export const SPECIFIC_FAIL = "SPECIFIC USER FAILED";

export const GET_ALL_USSD = "START GETTING ALL USSD";
export const ALL_USSD_SUCCESS = "ALL USSD FETCHED";
export const ALL_USSD_ERROR = "FAILED TO FETCH ALL USSD";
export const ALL_USSD_RESET = "RESET FETCHING ALL USSD";

export const START_GET_SINGLE = "START SINGLE PROFILE";
export const SINGLE_PROFILE_SUCCESS = "SUCCESSFULLY SINGLE PROFILE";
export const SINGLE_PROFILE_FAILED = "FAILED FETCHING PROFILE";
export const SINGLE_PROFILE_RESET = "RESET SINGLE PROFILE";

export const START_GET_LOCATIONS = "START GETTING ALL LOCATIONS";
export const GET_LOCATIONS_FAIL = "GETTING LOCATIONS FAILED";
export const GET_LOCATIONS_SUCCESS = "GETTING ALL LOCATIONS_SUCCESS";
export const GET_LOCATIONS_RESET = "RESET GETTING LOCATIONS";

export const START_GET_HISTORY = "START GETTING ALL HISTORY";
export const GET_HISTORY_FAIL = "GETTING HISTORY FAILED";
export const GET_HISTORY_SUCCESS = "GETTING ALL HISTORY_SUCCESS";
export const GET_HISTORY_RESET = "RESET GETTING HISTORY";

export const formatDate = (date) => moment(date).format("LL");
