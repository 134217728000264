import React from 'react';
//import Printer, { print } from 'react-pdf-print'

const Ftth = props => {
    const ids = ['1']
    return (
        <div>
            <p>On coming soon !!</p>

        </div>
    )
}
export default Ftth;