import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/PacmanLoader";
import { toaster } from "evergreen-ui";
import { validateUsers } from "../../utils/gql";
import { useLazyQuery } from "@apollo/client";
import { useAlert } from "react-alert";

import { loginReset } from "../../actions/login";

import { setUsername, setRole, loginx } from "../../actions/auth";

const styles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  topHeaders: {
    backgroundColor: "red",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  profile: {
    marginTop: "5px",
  },
  mycard: {
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: "50%",
    },
    backgroundColor: "#b3b4b5",
    height: "100vh",
  },
  panzer: {
    backgroundColor: "red",
  },
}));

export default function Login(props) {
  const classes = styles(props);
  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    login: state.login,
    auth: state.auth,
  }));
  const [username, setUser] = React.useState("");
  const [password, setPass] = React.useState("");
  const alert = useAlert();
  const handlePassword = (e) => setPass(e.target.value);
  const handleUsername = (u) => setUser(u.target.value);
  const auth = useLazyQuery(validateUsers, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.checkUsers) {
        dispatch(loginx());
        dispatch(setRole(data.checkUsers.role));
        dispatch(setUsername(data.checkUsers.username));
        sessionStorage.setItem("role", data.checkUsers.role);
        sessionStorage.setItem("user", data.checkUsers.username);
        dispatch(loginReset());
        props.history.replace("/");
      }
    },
    onError: (err) => {
      toaster.danger(
        err.message ? err.message : " Something went wrong. Try again please",
        {
          id: "danger-action",
        }
      );
    },
  });

  const goToHome = async () => {
    if (username.length < 10 || password < 8) {
      alert.show("password should be atleast 8 characters and username  10", {
        type: "error",
        containerStyle: { width: "50%" },
      });
      return;
    }
    auth[0]({ variables: { username, password } });
    // if (username !== "" && password.length >= 8) {
    //   dispatch(loginReset());
    //   dispatch(startLogin());
    //   await fetch(`${devUrl}api/v1/admin/login`, {
    //     method: "post",
    //     headers: {
    //       Accept: "application/json",
    //       "content-type": "application/json",
    //     },
    //     body: JSON.stringify({ email: username, password }),
    //   })
    //     .then((res) => res.json())
    //     .then((res) => dispatch(loginSuccess(res)))
    //     .catch((error) => dispatch(loginFail(error.message)));
    // }
    //props.history.replace("/admin/dashboard");
  };

  // if(auth[1].error){
  //   toaster.danger(auth[1].error.message ? auth[1].error.message : " Something went wrong. Try again please", {
  //     id: 'forbidden-action'
  //   })
  // }

  // React.useEffect(() => {
  //   if (state.login.success && state.login.data.status === 400) {
  //     if (state.login.data.data) {
  //       state.login.data.data.map((item) => {
  //         alert.show(item.msg, {
  //           type: "error",
  //           containerStyle: { width: "50%" },
  //         });
  //       });
  //     } else {
  //       alert.show(state.login.data.error, {
  //         type: "error",
  //         containerStyle: { width: "50%" },
  //       });
  //     }
  //   }
  //   if (state.login.success && state.login.data.status === 200) {

  //     //dispatch(setEmail(state.login.data.data.email));
  //     //dispatch(setToken(state.login.data.data.token));

  //   }
  // }, [state.login]);

  React.useEffect(() => {
    if (state.auth.isLoggedIn) {
      props.history.replace("/");
    }
  }, []);

  return (
    <LoadingOverlay
      active={auth[1].loading}
      spinner={<BounceLoader />}
      styles={{
        wrapper: (base) => ({
          ...base,
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }),
      }}
    >
      <GridContainer
        style={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "#3a2599",
        }}
      >
        <GridItem
          container
          direction="row"
          alignItems="center"
          justify="center"
          xs={12}
          sm={12}
          md={4}
          className={classes.mycard}
        >
          <Card style={{ paddingBottom: 80, marginTop: "25%" }}>
            <CardHeader color="warning" className={classes.panzer}>
              <h4 className={classes.cardTitleWhite}>
                Liquid Intelligent Technologies Comm Portal
              </h4>
              <p className={classes.cardCategoryWhite}>Login</p>
            </CardHeader>
            <CardBody>
              <GridContainer
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <GridItem xs={12} sm={12} md={8}>
                  <CardAvatar profile className={classes.profile}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        src={require("../../assets/img/lit.png")}
                        alt="..."
                      />
                    </a>
                  </CardAvatar>
                </GridItem>
              </GridContainer>
              <GridContainer
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "100%" }}
              >
                <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
                  <CustomInput
                    labelText="Email"
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      color: "info",
                    }}
                    color="underlineError"
                    value={username}
                    onChange={(text) => handleUsername(text)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "100%" }}
              >
                <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
                  <CustomInput
                    type="password"
                    labelText="Password"
                    id="city"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={password}
                    onChange={(text) => handlePassword(text)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="warning" rounded block onClick={() => goToHome()}>
                Login
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </LoadingOverlay>
  );
}
