import React from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import moment from 'moment';
import RedoIcon from '@material-ui/icons/Redo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Pane, Dialog, toaster } from 'evergreen-ui'
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import Select from 'react-dropdown-select'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getNewClients, fetchMore, assignNewClient, fetchCompany, editNewClient } from '../../utils/gql';
import update from 'immutability-helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

}));



const NewClients = props => {
  const { url, path } = useRouteMatch();
  const [isShown, setIsShown] = React.useState(false)
  const [info, setData] = React.useState([]);
  const [clData, setInfo] = React.useState(null);
  const [company, setCompany] = React.useState([]);
  const [company_id, setID] = React.useState(null);
  const History = useHistory();
  const classes = useStyles();

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  function compareFunction(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other._id == current._id
      }).length == 0;
    }
  }

  const { error, loading, data } = useQuery(getNewClients, { errorPolicy: "all", fetchPolicy: "cache-and-network" });
  const edituser = useMutation(editNewClient, { errorPolicy: 'all' })
  const companies = useQuery(fetchCompany, {
    errorPolicy: "all", fetchPolicy: "cache-and-network", onError: err => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`
          })
        })
      }
      else {
        toaster.danger("Something is wrong with your connection. Please check your internet");
      }
    },
    onCompleted: data => {
      let tmp_comp = [];
      if (data.getCompany != null) {
        data.getCompany.map(item => {
          if (item.status == true) {
            tmp_comp.push({
              label: item.name,
              value: item._id
            })
          }
        })
        setCompany(tmp_comp);
      }
    }
  })
  const assign = useMutation(assignNewClient, {

    errorPolicy: "all", onCompleted: data => {
        if (data.assignNewClient != null) {
        var original = info.filter(compareFunction(data.assignNewClient));
        var returned = data.assignNewClient.filter(compareFunction(info));
        var result = original.concat(returned);
        result.sort(dynamicSort("firstName"));
        setData(result);
        toaster.success("Client successfully assigned");

      }
    },
    onError: err => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`
          })
        })
      }
      else {
        toaster.danger("Something is wrong with your connection. Please check your internet");
      }
    }
  });
  const more = useLazyQuery(fetchMore, {
    errorPolicy: "all", fetchPolicy: "cache-and-network", onCompleted: res => {
      //console.log(res)
      if (res.newClients != null) {
        let tmp_data = [];
        let tmp_ = [...info, ...res.newClients].map(item => {
          let temp = {};
         
          temp = update(item, {
              date: {$set: formatDate(item.date)}
          })
          tmp_data.push(temp);
      });
        tmp_data.sort(dynamicSort("firstName"));
        setData(tmp_data);
        //resolve();
      }
    },
    onError: err => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`
          })
        })
      }
      else {
        toaster.danger("Something is wrong with your connection. Please check your internet");
      }
    }
  })

  React.useEffect(() => {
    if (data) {
      if (data.getNewClients != null) {
      let tmp_data = [];
        let tmp_ = [...data.getNewClients].map(item => {
          let temp = {};
         
          temp = update(item, {
              date: {$set: formatDate(item.date)}
          })
          tmp_data.push(temp);
      });
        tmp_data.sort(dynamicSort("firstName"));
        setData(tmp_data);
      }
    }

  }, [loading])

  const formatDate = date => `${moment(date).format('LL')}`;

  const handleFetch = async () => {
    more[0]()
  }

  const handleConfirm = async () => {

    if (company_id == null) {
      toaster.danger("Please choose a company");
      return;
    }

    if (clData == null) {
      toaster.danger("Something went wrong. Try again ");
      return;
    }
    else {
      setIsShown(false);
      let tmp_arr = [];
      if (Array.isArray(clData)) {
        clData.map(item => {
          tmp_arr.push({ id: item._id, company_id: company_id[0].value })
        })
        assign[0]({ variables: { client: JSON.stringify(tmp_arr) } })

      }
    }

  }

  const magicNumber = number => {
    let tmp = number.split('');
    if (tmp[0] == "2" && tmp[1] == "5" && tmp[2] == "6" && tmp[3] == "7") {
      if (tmp.length == 12) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }



  return (
    <div>
      <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
        <CardHeader color="info">
          <h3 className={[classes.cardTitleWhite]}>{'New installations'}</h3>
        </CardHeader>
        <CardBody>
          <MaterialTable

            options={{
              searchFieldStyle: {
                color: "black",
                borderBottomColor: "black",
              },
              pageSize: 10,
              pageSizeOptions: [10],
              search: true,
              filter: true,
              selection: true,
              selectionProps: rowData => ({
                color: 'primary',
                backgroundColor: "green"
              }),
              actionsColumnIndex: -1
            }}
            columns={[
              {
                title: "Date", field: "date", editable: false, render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.date}</p>
                  )
                }
              },
              {
                title: "Names", field: "firstName", render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                  )
                }
              },
              {
                title: "Account", field: "account", editable: false, render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                  )
                }
              },
              {
                title: "Contact", field: "contact", render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact}</p>
                  )
                }
              },
              {
                title: "Package", field: "category", editable: false, render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.category}</p>
                  )
                }
              },
              {
                title: "City", field: "city", render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city}</p>
                  )
                }
              },
              {
                title: "Street", field: "street", render: row => {
                  return (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.street}</p>
                  )
                }
              },
            ]}

            data={info.map(o => ({ ...o }))}
            onRowClick={(e, RowData) => {
              History.push({ pathname: `${url}/clientDetails` })
            }}

            editable={{
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  const str = newData.firstName
                
                  const numRes = magicNumber(newData.contact);
                  if (str == "" || str.length < 3) {

                    toaster.danger('The name should be greater than 5 characters');
                    resolve();
                  }
    
                  else if (numRes == false || newData.contact == "") {
                    toaster.danger('Wrong contact supplied. Contact must be in +25675..... format');
                    resolve();
                  }
                  else if (newData.city == "" || newData.city.length < 4) {
                    toaster.danger('Wrong city details. should be atleast 4 characters');
                    resolve();
                  }
                  else if (newData.street == "" || newData.street.length < 4) {
                    toaster.danger('Wrong street details. should be atleast 4 characters');
                    resolve();
                  }

                  else {
                    const datArr = [...info]
                    edituser[0]({ variables: { id: newData._id, firstName: str, contact: newData.contact, street: newData.street, city: newData.city } }).then(res => {
                      
                      if (res.data.editNewClient !== null) {
                        const arr = datArr.findIndex(item => item._id == newData._id);
                        datArr[arr] = res.data.editNewClient;
                        
                        setData(datArr);
                        toaster.success("Operation successful", {
                          zIndex: 20,
                        })
                        resolve()
                      }
                      if (res.errors) {
                        if (Array.isArray(res.errors)) {
                          res.errors.map((error, index) => {
                            toaster.danger(error.message, {
                              id: `err_${index}`
                            })
                          })
                          resolve();
                        }
                        else {
                          toaster.danger("Something is wrong with your connection. Please check your internet");
                          resolve();
                        }

                      }

                    }).catch(err => {

                      console.log(err)
                      resolve();
                    })

                  }
                }),
              onRowDelete: oldData =>
                new Promise((resolve) => {
                  resolve();
                  //console.log(oldData)
                })

            }}

            actions={[
              {
                icon: RedoIcon,
                tooltip: 'Assign to Contractors',
                onClick: (event, rowData) => {
                  console.log(rowData)
                  setIsShown(true);
                  setInfo(rowData);
                }
              }
            ]}

            localization={{
              body: {
                addTooltip: "Fetch more clients"
              }
            }}

            isLoading={loading || more[1].loading || assign[1].loading || companies.loading || edituser[1].loading}
            title={""}
            //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
            components={{
              OverlayLoading: (props) => (
                <div
                  {...props}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <BounceLoader color="#00acc1" size={50} {...props} />
                </div>
              ),

              Toolbar: props => {
                //console.log(props)
                return (
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                  <MTableToolbar {...props} />
                  <div style={{ marginLeft: 10 }}>
                    <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                      <TextsmsIcon size="small" />
                    </Fab>
                  </div>

                  <div style={{ marginLeft: 10 }}>
                    <Fab aria-label="Get new clients" style={{ color: "white", backgroundColor: "#00acc1" }} size="small" onClick={handleFetch}>
                      <CloudDownloadIcon size="small" />
                    </Fab>
                  </div>
                </div>
                )
              }
            }}

            
          />
          <Pane>

            <Dialog
              isShown={isShown}
              title="Assign installation"
              onCloseComplete={() => setIsShown(false)}
              onConfirm={handleConfirm}
              onCancel={() => setIsShown(false)}
            >
              <Pane height={200} width="100%" >
                <Pane>
                  <Select
                    multi={false}
                    isClearable
                    placeholder={'Assign to contractors'}
                    options={company}
                    onChange={values => { setID(values) }}
                    style={{
                      zIndex: 5,
                      backgroundColor: '#fff',
                      borderRadius: 5,
                      flex: 1,
                      width: 250
                    }}
                  />
                </Pane>
              </Pane>
            </Dialog>

          </Pane>
        </CardBody>
      </Card>
    </div>
  )
}
export default NewClients;