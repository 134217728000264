import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { useAlert } from "react-alert";
import MaterialTable, { MTableToolbar } from "material-table";
import Fab from "@material-ui/core/Fab";
import BounceLoader from "react-spinners/MoonLoader";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  SideSheet,
  Paragraph,
  Heading,
  Pane,
  TextInputField,
  Card as Cards,
  Position,
  Label,
  Textarea,
  toaster,
  CornerDialog,
} from "evergreen-ui";
import TextsmsIcon from "@material-ui/icons/Textsms";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Switch from "react-switch";
import { useMutation } from "@apollo/client";
import {
  deleteApns,
  createNewApn,
  updateApns,
  queryApns,
  sendToHighsite,
  mailAp,
} from "../../utils/gql";
import { useSelector, useDispatch } from "react-redux";
import { setHighsite } from "../../actions/_setHigh";
import { setID } from "../../actions/_setID";
import { setMac } from "../../actions/_setMac";
import { modify_apn, create_new_apn } from "../../actions/_setApns";
import { setCategory } from "../../actions/_setCategory";
import { currentRoute } from "../../actions/_choosePage";
import BounceLoader2 from "react-spinners/SyncLoader";
import uuid from "react-uuid";
import { useLazyQuery } from "@apollo/client";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddBoxIcon from "@material-ui/icons/AddBox";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  container4: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
};

const useStyles = makeStyles(styles);

export default function Highsites() {
  const classes = useStyles();

  const [mailBody, setMail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [info, setData] = React.useState([]);
  const [isShown, setShow] = React.useState(false);
  const [sms, setSMS] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [cat, setCat] = React.useState(false);
  const [err, setError] = React.useState(false);
  const [display, showDisplay] = React.useState(false);
  //const [openText, setText] = React.useState(false);
  const [openText, setText] = React.useState(false); // controls the message slide in pane
  const tabRef = React.createRef(); // creating a reference to the material table
  const alert = useAlert();
  const History = useHistory(); // Using the history object exposed by react-router-dom
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch(); // tracking the react-router-dom routes
  const state = useSelector((state) => ({
    name: state.name,
    id: state.id,
    apns: state.apns,
    tower: state.name.tower,
    role: state.auth.role,
    user: state.auth.username,
  }));
  let tmp_cat = false;

  // Navigating to the details page
  const handleSpecific = async () => {
    History.push({ pathname: `${url}/Clients` });
  };

  // open the side bar for registering new highsite

  // close the side bar for registering highsite
  // toggling between message mode type
  const setMessageMode = () => {
    setSMS(!sms);
  };

  // creating a new highsite via the create mutation exported from utils
  //const postHighsite = useMutation(addHighsites)
  const send_mail = useMutation(mailAp, { errorPolicy: "all" });
  const editApn = useMutation(updateApns, { errorPolicy: "all" });
  const deleteapns = useMutation(deleteApns, { errorPolicy: "all" });
  const send_msg = useMutation(sendToHighsite, { errorPolicy: "all" });
  const apns = useLazyQuery(queryApns, { errorPolicy: "all" });

  const newapns = useMutation(createNewApn, {
    errorPolicy: "all",
    update: (cache, { data: { createApn } }) => {
      const { getApns } = cache.readQuery({
        query: queryApns,
        variables: { name: state.tower },
      });
      //let tmp = getApns.map(element => element._id == state.id ? Object.assign({}, element, { clients: element.clients + 1 }) : element);
      //console.log(tmp)
      //
      cache.writeQuery({
        query: queryApns,
        data: {
          apn: [...getApns, createApn],
        },
      });
    },
    onCompleted: (da) => {
      if (da.createApn != null) {
        dispatch(create_new_apn(da.createApn));
      }
    },
  });

  const handleMessage = async () => {
    if (message == "" || message.length < 20) {
      toaster.danger("Message should be atleast 20 characters in length");
      return;
    } else {
      try {
        const { errors, data } = await send_msg[0]({
          variables: { tower: state.name.tower, message, user: state.user },
        });
        if (errors && Array.isArray(errors)) {
          toaster.danger(errors[0].message);
        }

        if (data != null) {
          const tmp = JSON.parse(data.notifySite.message);
          if (tmp.isError == true) {
            toaster.danger(tmp.message);
          } else {
            toaster.success(
              "The SMS message has been successfully shared with the clients"
            );
            toggleText();
            setMessage("");
            showDisplay(true);
          }
        }
      } catch (e) {
        // console.log(e)
        toaster.danger("Something happened");
      }
    }
  };

  const handleMessage2 = async () => {
    //console.log(mailBody, "=====>", subject)
    if (
      mailBody == "" ||
      mailBody.length < 20 ||
      subject == "" ||
      subject.length < 5
    ) {
      toaster.danger(
        "Message should be atleast 20 characters in length and subject 5"
      );
      return;
    } else {
      try {
        const { errors, data } = await send_mail[0]({
          variables: {
            tower: state.name.tower,
            message: mailBody,
            type: "email",
            subject,
          },
        });
        if (errors && Array.isArray(errors)) {
          toaster.danger(errors[0].message);
        }

        //console.log(data, )

        if (data != null) {
          const tmp = JSON.parse(data.mailSite.message);
          if (tmp.isError == true) {
            toaster.danger(tmp.message);
          } else {
            toaster.success(
              "The email message has been successfully shared with the clients"
            );
            toggleText();
            setSubject("");
            setMail("");
            showDisplay(true);
          }
        }
      } catch (e) {
        console.log(e);
        toaster.danger("Something wrong happened");
      }
    }
  };

  const toggleText = () => {
    setText(!openText);
  };

  React.useState(() => {
    setData(state.apns.Apns);
  }, [state.apns]);

  React.useState(() => {
    dispatch(currentRoute(path));
    return () => {
      apns[0]({ variables: { name: state.name.tower } });
    };
  }, []);

  const createNewMac = () => {
    return uuid();
  };

  React.useEffect(() => {
    //console.log(state.role)
    setData(state.apns.Apns);
  }, []);

  const handleSmsChange = (event) => {
    const text = event.target.value;
    if (text.length <= 160) {
      setError(false);
      setMessage(text);
    } else {
      setError(true);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Liquid Tech APs</h4>
            <p className={classes.cardCategoryWhite}>{state.name.tower}</p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              tableRef={tabRef}
              editable={{
                onRowAdd:
                  state.role != "liquid_care"
                    ? (newData) =>
                        new Promise((resolve) => {
                          const str = newData.name;
                          //setShow(true)
                          //const result = validateIp(newData.ip)
                          if (str.length < 5) {
                            toaster.danger(
                              "Access point name should be greater than 5 characters",
                              {
                                zIndex: 20,
                              }
                            );
                            resolve();
                          } else {
                            newapns[0]({
                              variables: {
                                name: newData.name,
                                mac: createNewMac(),
                                category: tmp_cat ? "p2p" : "sector",
                                tower: state.name.tower,
                                clients: newData.clients
                                  ? Number(newData.clients)
                                  : 0,
                              },
                            }).then((res) => {
                              if (res.data.createApn != null) {
                                setData((prev) => [
                                  ...prev,
                                  res.data.createApn,
                                ]);
                                setCat(false);
                                apns[0]({ variables: { name: state.tower } });
                                toaster.success("Operation successful", {
                                  zIndex: 20,
                                });
                                tmp_cat = false;
                                resolve();
                              }
                              if (res.data.createApn == null && res.errors) {
                                if (Array.isArray(res.errors)) {
                                  res.errors.map((error, index) => {
                                    toaster.danger(error.message, {
                                      id: `err_${index}`,
                                    });
                                  });
                                  resolve();
                                } else {
                                  toaster.danger(
                                    "Something is wrong with your connection. Please check your internet"
                                  );
                                  resolve();
                                }
                                tmp_cat = false;
                                resolve();
                              }
                            });
                            resolve();
                            //console.log(tmp_cat)
                          }
                        })
                    : null,
                onRowUpdate:
                  state.role != "liquid_care"
                    ? (newData) =>
                        new Promise((resolve) => {
                          if (newData.name == "" || newData.name.length < 5) {
                            toaster.danger(
                              "Highsite should be atleast 4 characters and IP in 192.168.0.1 format",
                              {
                                zIndex: 20,
                              }
                            );
                            resolve();
                          } else {
                            const datArr = [...info];
                            editApn[0]({
                              variables: {
                                id: newData._id,
                                name: newData.name,
                                category:
                                  newData.category == null
                                    ? null
                                    : newData.category == true
                                    ? "p2p"
                                    : "sector",
                              },
                            })
                              .then((res) => {
                                if (res.data.updateApn != null) {
                                  const arr = datArr.findIndex(
                                    (item) => item._id == newData._id
                                  );
                                  datArr[arr] = res.data.updateApn;
                                  dispatch(modify_apn(res.data.updateApn));
                                  setData(datArr);
                                  toaster.success("Operation successful", {
                                    zIndex: 20,
                                  });
                                  resolve();
                                }
                                if (res.errors) {
                                  if (Array.isArray(res.errors)) {
                                    res.errors.map((error, index) => {
                                      toaster.danger(error.message, {
                                        id: `err_${index}`,
                                      });
                                    });
                                    resolve();
                                  } else {
                                    toaster.danger(
                                      "Something is wrong with your connection. Please check your internet"
                                    );
                                    resolve();
                                  }
                                }
                              })
                              .catch((err) => console.log(err));
                            //console.log(newData.category)

                            resolve();
                          }
                        })
                    : null,
                onRowDelete:
                  state.role != "liquid_care"
                    ? (oldData) =>
                        new Promise((resolve) => {
                          deleteapns[0]({
                            variables: { id: oldData._id, mac: oldData.mac },
                          })
                            .then((res) => {
                              if (res.data.deleteApn != null) {
                                const arr = info.filter(
                                  (e) => e._id != res.data.deleteApn._id
                                );
                                toaster.success("APN successfully deleted", {
                                  zIndex: 20,
                                });
                                setData(arr);
                              }
                              if (res.errors) {
                                if (Array.isArray(res.errors)) {
                                  res.errors.map((error, index) => {
                                    toaster.danger(error.message, {
                                      id: `err_${index}`,
                                    });
                                  });
                                  resolve();
                                } else {
                                  toaster.danger(
                                    "Something is wrong with your connection. Please check your internet"
                                  );
                                  resolve();
                                }
                              }
                            })
                            .catch((err) => {
                              toaster.danger("Something went wrong");
                            });
                          resolve();
                        })
                    : null,
              }}
              options={{
                searchFieldStyle: {
                  color: "black",
                  borderBottomColor: "black",
                },
                pageSize: 10,
                pageSizeOptions: [10],
                actionsColumnIndex: -1,
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData?.newClients > 0 ? "#52b8eb" : "#FFFFFF",
                }),
              }}
              columns={[
                {
                  title: "Name",
                  field: "name",
                  render: (row) => {
                    return (
                      <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                        {row.name}
                      </p>
                    );
                  },
                },
                {
                  title: "IP",
                  field: "ip",
                  editable: false,
                  render: (row) => {
                    return (
                      <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                        {row.ip}
                      </p>
                    );
                  },
                },
                {
                  title: "Clients",
                  field: "clients",
                  editable: false,
                  render: (row) => {
                    if (Number(row?.newClients) > 0) {
                      return (
                        <p
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 15,
                          }}
                        >
                          {row.clients}
                        </p>
                      );
                    } else {
                      return (
                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                          {row.clients}
                        </p>
                      );
                    }
                  },
                },

                {
                  title: "Category",
                  field: "category",
                  editComponent: (props) => {
                    //console.log(props.value, '-----> render component')
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: props.value == true ? "green" : "black",
                            fontFamily: "Roboto",
                            fontSize: 15,
                          }}
                        >
                          P2P
                        </p>
                        <Switch
                          checked={
                            props.value == "sector"
                              ? false
                              : props.value == null
                              ? false
                              : props.value == "p2p"
                              ? true
                              : props.value == true
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            props.onChange(e);
                            tmp_cat = e;
                          }}
                          checkedIcon={<PersonAddIcon />}
                          uncheckedIcon={<GroupAddIcon />}
                          width={70}
                        />

                        <p
                          style={{
                            color: props.value == false ? "green" : "black",
                            fontFamily: "Roboto",
                            fontSize: 15,
                          }}
                        >
                          PMP/Sector
                        </p>
                      </div>
                    );
                  },
                  render: (dara) => (
                    <p style={{ fontFamily: "Roboto", fontSize: 15 }}>
                      {dara.category == null
                        ? "unknown"
                        : dara.category == "p2p"
                        ? "P2P"
                        : "PMP/Sector"}
                    </p>
                  ),
                },
              ]}
              data={info.map((o) => ({ ...o }))}
              onRowClick={(e, RowData) => {
                dispatch(setHighsite(RowData.name));
                handleSpecific(RowData._id);
                dispatch(setID(RowData._id));
                dispatch(setMac(RowData.mac));
                dispatch(setCategory(RowData.category));
                //dispatch(setApnId())
              }}
              isLoading={
                editApn[1].loading ||
                deleteapns[1].loading ||
                newapns[1].loading
              }
              title={""}
              //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
              components={{
                OverlayLoading: (props) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <BounceLoader color="#00acc1" size={50} {...props} />
                  </div>
                ),

                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <MTableToolbar {...props} />
                    <div>
                      <Fab
                        aria-label="add"
                        onClick={() => {
                          setText(true);
                        }}
                        style={{ color: "white", backgroundColor: "#00acc1" }}
                        size="small"
                      >
                        <TextsmsIcon size="small" />
                      </Fab>
                    </div>
                  </div>
                ),
              }}
              icons={{
                Add: (props) => (
                  <div>
                    <Fab
                      aria-label="add"
                      {...props}
                      style={{ color: "white", backgroundColor: "#00acc1" }}
                      size="small"
                    >
                      <AddBoxIcon size="small" />
                    </Fab>
                  </div>
                ),
              }}
              localization={{
                body: {
                  addTooltip: "Add new Access Point",
                },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <React.Fragment>
        <SideSheet
          isShown={openText}
          onCloseComplete={() => {
            setText(false);
          }}
          position={Position.LEFT}
          style={{ zIndex: 20 }}
        >
          <Pane
            zIndex={10}
            flexShrink={0}
            elevation={0}
            backgroundColor="white"
          >
            <Pane padding={16}>
              <Heading size={600}>Message clients</Heading>
              <Paragraph size={400}>Inform clients via SMS or email</Paragraph>
            </Pane>
          </Pane>
          <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
            <Cards
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Paragraph size={400}>
                {sms ? "Send SMS to clients" : "Send email to clients"}
              </Paragraph>
              <Switch
                onChange={setMessageMode}
                checked={sms}
                checkedIcon={<PhoneAndroidIcon color="#00acc1" />}
                uncheckedIcon={<EmailIcon color="#00acc1" />}
                onColor="#00acc1"
                offColor="#00acc1"
              />
            </Cards>
            <Cards
              backgroundColor="white"
              elevation={0}
              flexDirection="column"
              padding={10}
              display="flex"
            >
              {!sms ? (
                <Pane>
                  <TextInputField
                    label="Subject"
                    inputWidth={350}
                    placeholder="Message subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <Label htmlFor="textarea-2" marginBottom={4} display="block">
                    Message body
                  </Label>
                  <Textarea
                    id="textarea-1"
                    value={mailBody}
                    onChange={(e) => setMail(e.target.value)}
                    width={350}
                    placeholder="Your message to clients...."
                  />
                  <Cards display="flex" flexDirection="row" width={350}>
                    <Button color="info" rounded block onClick={handleMessage2}>
                      {send_mail[1].loading ? (
                        <BounceLoader2 color="white" />
                      ) : (
                        <p>Send</p>
                      )}
                    </Button>
                  </Cards>
                </Pane>
              ) : (
                <>
                  <Label htmlFor="textarea-2" marginBottom={4} display="block">
                    SMS Message
                  </Label>
                  <Textarea
                    id="textarea-3"
                    width={350}
                    placeholder="Your sms to clientsxxx...."
                    value={message}
                    onChange={handleSmsChange}
                    style={{ backgroundColor: err ? "red" : "white" }}
                  />
                  <Cards display="flex" flexDirection="row" width={350}>
                    <Button color="info" rounded block onClick={handleMessage}>
                      {send_msg[1].loading ? (
                        <BounceLoader2 color="white" />
                      ) : (
                        <p>Send</p>
                      )}
                    </Button>
                  </Cards>
                </>
              )}
            </Cards>
          </Pane>
        </SideSheet>
      </React.Fragment>

      <React.Fragment>
        <CornerDialog
          title="Welcome to this new feature"
          isShown={isShown}
          onCloseComplete={() => setShow(false)}
        >
          The corner dialog component is used for new feature announcements and
          feedback requests from the user.
        </CornerDialog>
      </React.Fragment>
    </GridContainer>
  );
}
