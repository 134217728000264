import React from 'react';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import RedoIcon from '@material-ui/icons/Redo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Pane, Dialog, toaster } from 'evergreen-ui'
import Select from 'react-dropdown-select'
import { useMutation, useQuery } from '@apollo/client';
import { fetchFailed, fetchCompany, reassigning } from 'utils/gql';
import moment from 'moment';

//import Printer, { print } from 'react-pdf-print'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

}));


const Failed = props => {
    const [isShown, setIsShown] = React.useState(false)
    const [info, setData] = React.useState([]);
    const classes = useStyles();
    const [company, setCompany] = React.useState([]);
    const [company_id, setID] = React.useState(null);
    const [datam, setInfo] = React.useState(null);
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }


    const { loading, data } = useQuery(fetchFailed, { errorPolicy: 'all', fetchPolicy: 'cache-and-network' })
    const sign = useMutation(reassigning, {errorPolicy: 'all', onError: err => {
        //console.log(err)
        if(Array.isArray(err)){
            err.map((error, index) => {
              toaster.danger(error.message, {
                id: `err_${index}`
              })
            })
          }
          else{
            toaster.danger("Something is wrong with your connection. Please check your internet");
          }
    }, onCompleted: res => {
        if(res.reAssignJob != null){
            toaster.success("Re-assigning job done")
        }
    }})
    const formatDate = date => `${moment(date).format('LL')}`;
    const companies = useQuery(fetchCompany, {
        errorPolicy: "all", fetchPolicy: "cache-and-network", onError: err => {
          if(Array.isArray(err)){
            err.map((error, index) => {
              toaster.danger(error.message, {
                id: `err_${index}`
              })
            })
          }
          else{
            toaster.danger("Something is wrong with your connection. Please check your internet");
          }
        },
        onCompleted: data => {
          let tmp_comp = [];
          if (data.getCompany != null) {
            data.getCompany.map(item => {
              if (item.status == true) {
                tmp_comp.push({
                  label: item.name,
                  value: item._id
                })
              }
            })
            setCompany(tmp_comp);
          }
        }
      })

    React.useEffect(() => {
        if (data) {
            if (data.getFailed != null) {
                let tmp_ = [...data.getFailed];
                console.log(tmp_)
                tmp_.sort(dynamicSort("firstName"));
                setData(tmp_);
            }
        }

    }, [loading])

    const handleConfirm = () => {
        if(company_id == null){
            toaster.danger("Please choose a company to proceed");
            return;
        }

        if(company_id == datam.assigned_company._id){
            toaster.danger("Can not re-assign job on the same company");
            return;
        }
        setIsShown(false)
        sign[0]({variables: {id: datam._id, old_comp: datam.assigned_company._id, new_comp: company_id, old_tech: datam.assigned_tech? datam.assigned_tech._id : null, new_tech: null, state: "failed"}})

    }
    return (
        <div>
            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={[classes.cardTitleWhite]}>{'All installations and Troubleshoots in Progress'}</h3>
                </CardHeader>
                <CardBody>
                    

                    <MaterialTable

                        options={{
                            searchFieldStyle: {
                                color: "black",
                                borderBottomColor: "black",
                            },
                            pageSize: 10,
                            pageSizeOptions: [10],
                            search: true,
                            filter: true,
                            actionsColumnIndex: -1
                        }}
                        columns={[
                            {
                                title: "Name", field: "firstName", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.firstName}</p>
                                    )
                                }
                            },
                            {
                                title: "Account", field: "account", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.account}</p>
                                    )
                                }
                            },
                            {
                                title: "Assigned On", field: "assignedOn", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{formatDate(row.assignedOn)}</p>
                                    )
                                }
                            },
                            {
                                title: "Location", field: "city", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.city ? row.city : " Not assigned"}</p>
                                    )
                                }
                            },
                            
                            {
                                title: "Company", field: "assigned_company.name", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_company ? row.assigned_company.name : " Not provided"}</p>
                                    )
                                }
                            },
                            {
                                title: "assignedTo", field: "assigned_tech.name", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.assigned_tech ? row.assigned_tech.name : "Unassigned"}</p>
                                    )
                                }
                            },
                            {
                                title: "Contact", field: "contact", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact ? row.contact : "Not assigned"}</p>
                                    )
                                }
                            },

                            {
                                title: "Type", field: "mode", render: row => {
                                    return (
                                        <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.mode}</p>
                                    )
                                }
                            }

                        ]}

                        data={info.map(o => ({ ...o }))}
                        onRowClick={(e, RowData) => {
                            //History.push({ pathname: `${url}/clientDetails` })
                        }}

                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                           
                                }),
                              onRowUpdate: (newData) =>
                                new Promise((resolve) => {
                           
                                }),
                              onRowDelete: oldData =>
                                new Promise((resolve) => {
                                  resolve();
                                  //console.log(oldData)
                                })

                        }}

                        actions={[
                            {
                                icon: RedoIcon,
                                tooltip: 'Assign to Contractors',
                                onClick: (event, rowData) => {
                                    setIsShown(true);
                                    setInfo(rowData);
                                }
                            }
                        ]}

                        localization={{
                            body: {
                                addTooltip: "Fetch more clients"
                            }
                        }}

                        isLoading={ loading || companies.loading || sign[1].loading }
                        title={""}
                        //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
                        components={{
                            OverlayLoading: (props) => (
                                <div
                                    {...props}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <BounceLoader color="#00acc1" size={50} {...props} />
                                </div>
                            ),

                            Toolbar: props => (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
                                    <MTableToolbar {...props} />
                                    <div style={{ marginLeft: 10 }}>
                                        <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                                            <TextsmsIcon size="small" />
                                        </Fab>
                                    </div>
                                </div>
                            ),
                        }}
                    />

                    <Pane>

                        <Dialog
                            isShown={isShown}
                            title="Re-assign installation"
                            onCloseComplete={() => setIsShown(false)}
                            onCancel = {() => setIsShown(false)}
                            onConfirm = {handleConfirm}
                        >
                            <Pane height={200} width="100%" >
                                <Pane>
                                    <Select
                                        multi={false}
                                        isClearable
                                        placeholder={'Assign to contractors'}
                                        options={company}
                                        onChange={values => { setID(values[0].value) }}
                                        style={{
                                            zIndex: 5,
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                            flex: 1,
                                            width: 250
                                        }}
                                    />
                                </Pane>
                            </Pane>
                        </Dialog>

                    </Pane>

                </CardBody>
            </Card>

        </div>
    )
}
export default Failed;