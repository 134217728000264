import React from "react";
import NewClients from "./newClients";
import ClientDetails from "./clientDetails";
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";

const High = (props) => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <NewClients />
      </Route>
      <Route path={`${path}/clientDetails`}>
        <ClientDetails />
      </Route>
    </Switch>
  );
};

export default High;
