import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js"
import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.png";
import logo from "assets/img/reactlogo.png";
import { setUsername, setRole, loginx } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";

let ps;




const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  const { path, url } = useRouteMatch();
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    auth: state.auth,
  }));
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("purple");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const switchRoutes = data => (
    <Switch>
      {data.map((prop, key) => {
        if (prop.layout === "/home") {
         // console.log(prop.path)
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/home" to="/login" />
    </Switch>
  );
  

  const assignRoutes = () => {
    let tmp_routes = [];
    routes.map((prop, key) => {
      prop.roles.map(role => {
        if(role == state.auth.role){
          tmp_routes.push(prop)
        }
      })
    })
   //console.log(tmp_routes)
     return tmp_routes;
   }
  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);

  React.useEffect(() => {
    const role = sessionStorage.getItem("role" || "");
    const user = sessionStorage.getItem("user" || "");
    if (!state.auth.isLoggedIn) {
      if (role !== null) {
        dispatch(setRole(role));
        dispatch(loginx());
      }
      if (user !== null) {
        dispatch(setUsername(user));
      }
    }
  }, []);
  if (!state.auth.isLoggedIn) {
    return <Redirect push to="/login" />;
  }
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={assignRoutes()}
        logoText={"Liquid Telecom"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={assignRoutes()}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes(assignRoutes())}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes(assignRoutes())}</div>
        )}
        {getRoute() ? <Footer /> : null}
        
      </div>
    </div>
  );
}
