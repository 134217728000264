import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from "react-switch";
import { useSelector } from 'react-redux';
import { createUser } from '../../utils/gql';
import { useMutation } from '@apollo/client';
import { toaster } from 'evergreen-ui';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Select from 'react-dropdown-select'
import {
    SideSheet,
    Pane,
    Card as Cards,
    Paragraph,
    Heading,
    TextInputField,
    Position,
    Textarea,
    Label
} from "evergreen-ui";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


const ClientDetails = (props) => {
    const [openSide, setSide] = React.useState(false); // opening of the add client sideview
    const [hai, setHai] = React.useState(true) // controls the selection of the package category
    const [openText, setText] = React.useState(false); // controls the message slide in pane
    const [sms, setSMS] = React.useState(true);
    const [category, setCategory] = React.useState("GPON");


    const [status, setStatus] = React.useState(true);
    // function for toggling between the package type
    const setPackageType = () => {
        setHai(!hai);
        if (hai) {
            setCategory("GPON")
        } else {
            setCategory("HAI")
        }

    }
    // function for toggling btn the messaging type

    const setMessageMode = () => {
        setSMS(!sms)
    }



    const [adduser, { error, data, loading }] = useMutation(createUser, { errorPolicy: 'all' })

    if (error) {
        if (error.networkError) {
            error.networkError.result.errors.map(er => {
                toaster.danger(er.message, {
                    id: 'error-action'
                })
            })
        }
    }

    if (data) {
        toaster.success("User registered on the network", {
            id: 'success-action'
        })
    }

    const state = useSelector(state => ({
        sector: state.sector.sector,
        highsiteID: state.id.id,
        sectorID: state.sector.sectorID,
        highsite: state.name.name

    }))



    const classes = useStyles();
    return (
        <div style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
            <Card style={{ backgroundColor: "rgba(149, 193, 210, 0.1)" }}>
                <CardHeader color="info">
                    <h3 className={classes.cardTitleWhite}>{'movie'}</h3>
                </CardHeader>
                <CardBody>
                    
                    <GridContainer container>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader>
                                    <p className={classes.cardCategory}>Details</p>
                                </CardHeader>
                                <CardBody>
                                    <List className={classes.root}>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <TodayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={moment().format("MMM Do YY")} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`Matovu`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <LocalActivityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`GPON`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <PermContactCalendarIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`ltu-9074648`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <SupervisedUserCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`Edrona Nakiberu`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <RadioButtonCheckedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`Inactive`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <LocationOnIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`Kulambiro`} />
                                        </ListItem>
                                        <ListItem role={undefined} dense button onClick={() => { }}>
                                            <ListItemIcon>
                                                <ContactPhoneIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={`0773546382`} />
                                        </ListItem>
                                    </List>


                                </CardBody>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader>Actions</CardHeader>
                                <CardBody>
                                    <Pane>
                                        <Select
                                            multi={false}
                                            isClearable
                                            placeholder={'Assign to contractors'}
                                            options={[
                                                {
                                                    label: "Japotech", value: "Japotech",
                                                   
                                                },
                                                {
                                                    label: "Csquared", value: "Csquared"
                                                }
                                            ]}
                                            onChange={values => { console.log(values) }}
                                            style={{
                                                zIndex: 5,
                                                backgroundColor: '#fff',
                                                borderRadius: 5,
                                                flex: 1,
                                                width: 250
                                            }}
                                        />
                                    </Pane>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <SideSheet
                            isShown={openSide}
                            onCloseComplete={() => {
                                setSide(false);
                            }}
                        >
                            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                                <Pane padding={16}>
                                    <Heading size={600}>Add new client</Heading>
                                    <Paragraph size={400}>
                                        Register client on Buziga - Kampala
                            </Paragraph>
                                </Pane>
                            </Pane>
                            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                                <Cards display="flex" justifyContent="flex-start" alignItems="center">

                                    <Paragraph size={400}>
                                        {hai ? "Register GPON Client" : "Register HAI client"}
                                    </Paragraph>
                                    <Switch onChange={setPackageType} checked={hai} />

                                </Cards>
                                <Cards
                                    backgroundColor="white"
                                    elevation={0}
                                    flexDirection="column"
                                    padding={10}
                                    display="flex"

                                >
                                </Cards>
                            </Pane>
                        </SideSheet>
                        <SideSheet
                            isShown={openText}
                            onCloseComplete={() => {
                                setText(false);
                            }}
                            position={Position.BOTTOM}
                        >
                            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                                <Pane padding={16}>
                                    <Heading size={600}>Message clients</Heading>
                                    <Paragraph size={400}>
                                        Inform clients via SMS or email
              </Paragraph>
                                </Pane>
                            </Pane>
                            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                                <Cards display="flex" justifyContent="flex-start" alignItems="center">

                                    <Paragraph size={400}>
                                        {sms ? "Send SMS to clients" : "Send email to clients"}
                                    </Paragraph>
                                    <Switch onChange={setMessageMode} checked={sms} checkedIcon={<PhoneAndroidIcon />} uncheckedIcon={<EmailIcon />} />

                                </Cards>
                                <Cards
                                    backgroundColor="white"
                                    elevation={0}
                                    flexDirection="column"
                                    padding={10}
                                    display="flex"

                                >
                                    {!sms ? (
                                        <Pane>
                                            <TextInputField
                                                label="Subject"
                                                inputWidth={350}
                                                placeholder="Message subject"
                                            />
                                            <Label
                                                htmlFor="textarea-2"
                                                marginBottom={4}
                                                display="block"
                                            >
                                                Message body
                      </Label>
                                            <Textarea
                                                id="textarea-1"
                                                width={350}
                                                placeholder="Your message to clients...."
                                            />
                                        </Pane>
                                    ) : (
                                        <>
                                            <Label
                                                htmlFor="textarea-2"
                                                marginBottom={4}
                                                display="block"
                                            >
                                                SMS Message
                      </Label>
                                            <Textarea
                                                id="textarea-3"
                                                width={350}
                                                placeholder="Your sms to clients...."
                                            />
                                        </>
                                    )}


                                    <Cards display="flex" flexDirection="row" width={350}>
                                        <Button color="info" rounded block >
                                            Send
              </Button>
                                    </Cards>

                                </Cards>
                            </Pane>
                        </SideSheet>
                    </GridContainer>
                </CardBody>
            </Card>
        </div >
    );
};
export default ClientDetails;
