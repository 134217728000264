import * as Constants from "../utils/constants";

const initialState = {
  role: "",
  username: "",
  isLoggedIn: false,

};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROLE': {
      return {
        ...state,
        role: action.payload,
      };
    }
    case 'SET_USERNAME': {
      return {
        ...state,
        username: action.payload,
      };
    }
    case Constants.ISLOGGEDIN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case Constants.LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        role: "",
        username: ""
      };
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
