/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-fallthrough */
import * as Constants from '../utils/constants';

const initialState = {
  loading: false,
  data: null,
  success: false,
  error: false,
  error_msg: '',
  edit: null,
};

const SingleProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.START_GET_SINGLE: {
      return {
        ...state,
        loading: true,
      };
    }
    case Constants.SINGLE_PROFILE_FAILED: {
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    }
    case Constants.SINGLE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        edit: action.payload.data,
      };
    }
    case 'UPDATE_SINGLE': {
        return {
          ...state,
          edit:{
            ...state.edit,
            events: action.payload
          }
        };
      
    }

    case Constants.SINGLE_PROFILE_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        error_msg: '',
        success: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default SingleProfileReducer;
