import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components

import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";
import { useDispatch, useSelector } from "react-redux";
import { currentRoute } from "../../actions/_choosePage";
import { dailySalesChart, completedTasksChart } from "variables/charts.js";
import { useRouteMatch } from "react-router-dom";
import PuffLoader from "react-spinners/FadeLoader";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useQuery } from "@apollo/client";
import { Stats } from "utils/gql";
import { toaster } from "evergreen-ui";
import { WaveLoader } from "react-loaders-kit";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const state = useSelector((state) => ({
    path: state.category.path,
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const [stats, setStats] = React.useState({});
  React.useEffect(() => {
    dispatch(currentRoute(path));
  }, []);

  const { loading, data, error, refetch } = useQuery(Stats, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    onError: (err) => {
      if (Array.isArray(err)) {
        err.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`,
          });
        });
      } else {
        toaster.danger(
          "Something is wrong with your connection. Please check your internet"
        );
      }
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      setStats(data?.dashboard);
    }
  }, [loading]);

  React.useEffect(() => {
    dispatch(currentRoute(path));
    //console.log(data);
  }, []);
  return (
    <div>
      <GridContainer container>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>persons</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Highsites</p>

              <WaveLoader color="#52b8eb" loading={loading} />

              <h3 className={classes.cardTitle}>{stats?.towerCount ?? 0}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>persons</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Access points</p>
              <WaveLoader color="#52b8eb" loading={loading} />
              <h3 className={classes.cardTitle}>{stats?.apCount ?? 0}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Clients</p>
              <WaveLoader color="#52b8eb" loading={loading} />
              <h3 className={classes.cardTitle}>
                {stats?.userStats?.total ?? 0}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>New Clients</p>
              <WaveLoader color="#52b8eb" loading={loading} />
              <h3 className={classes.cardTitle}>
                {stats?.userStats?.new ?? 0}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Active Clients</p>
              <WaveLoader color="#52b8eb" loading={loading} />
              <h3 className={classes.cardTitle}>
                {stats?.userStats?.active ?? 0}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Inactive clients</p>
              <WaveLoader color="#52b8eb" loading={loading} />
              <h3 className={classes.cardTitle}>
                {stats?.userStats?.inactive ?? 0}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>

        {/* <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <p className={classes.cardCategory}>Trends in installations</p>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
