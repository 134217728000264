
export const setHighsite = name => dispatch => {
    dispatch({
      type: 'SET_NAME',
      payload: name,
    });
  };

  export const setTower = tower => dispatch => {
    dispatch({
      type: 'SET_TOWER',
      payload: tower
    })
  }