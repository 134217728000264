import { hexToRgb,  orangeColor } from "assets/jss/material-dashboard-react.js";

const cardAvatarStyle = {
  cardAvatar: {
    "&$cardAvatarProfile img": {
      width: "100%",
      height: "auto"
    }
  },
  cardAvatarProfile: {
    maxWidth: "130px",
    maxHeight: "130px",
    margin: "-50px auto 0",
    overflow: "hidden",
    padding: "0"
  },
  cardAvatarPlain: {}
};

export default cardAvatarStyle;
