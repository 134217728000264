import * as Constants from "../utils/constants";

const initialState = {
  loading: false,
  data: null,
  success: false,
  error: false,
  error_msg: "",
};

const HistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.START_GET_HISTORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case Constants.GET_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    }
    case Constants.GET_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    }
    case Constants.GET_HISTORY_RESET: {
      return {
        ...state,
        loading: false,
        error: false,
        error_msg: "",
        success: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default HistoryReducer;
