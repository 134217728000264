import React from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import BounceLoader from "react-spinners/MoonLoader";
import Fab from '@material-ui/core/Fab';
import TextsmsIcon from '@material-ui/icons/Textsms';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { fetchCompany, createCompany, editCompany } from '../../utils/gql';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import Switch from "react-switch";
import { toaster } from 'evergreen-ui'
import { useSelector, useDispatch } from 'react-redux';
import { setCompany } from 'actions/_setComp';

const Contractors = props => {

  const { url } = useRouteMatch();
  const History = useHistory();
  const [info, setData] = React.useState([]);
  let tmp_cat = false;
  const dispatch = useDispatch();

  
  const { error, loading, data } = useQuery(fetchCompany, {
    errorPolicy: "all", fetchPolicy: "cache-and-network", onError: err => {
      if(Array.isArray(err)){
        err.errors.map((error, index) => {
          toaster.danger(error.message, {
            id: `err_${index}`
          })
        })
      }
      else{
        toaster.danger("Something is wrong with your connection. Please check your internet");
      }
    }
  })
  const editComp = useMutation(editCompany, { errorPolicy: 'all' })
  const createComp = useMutation(createCompany, { errorPolicy: 'all' });
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  const magicNumber = number => {
    let tmp = number.split('');
    if (tmp[0] == "2" && tmp[1] == "5" && tmp[2] == "6" && tmp[3] == "7") {
      if (tmp.length == 12) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  const validateEmail = mail => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true
    }

    return false
  }

  React.useEffect(() => {
    if (data) {
      if (data.getCompany != null) {
        let tmp_ = [...data.getCompany];
        tmp_.sort(dynamicSort("name"));
        setData(tmp_);
        //console.log(tmp_)
      }
    }


  }, [loading])

  return (
    <div>
      <MaterialTable

        options={{
          searchFieldStyle: {
            color: "black",
            borderBottomColor: "black",
          },
          pageSize: 10,
          pageSizeOptions: [10],
          search: true,
          filter: true,
          actionsColumnIndex: -1
        }}
        columns={[
          {
            title: "Name", field: "name", render: row => {
              return (
                <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.name}</p>
              )
            }
          },
          {
            title: "Contractors", field: "number", editable: false, render: row => {
              return (
                <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.number}</p>
              )
            }
          },
          {
            title: "Location", field: "location", render: row => {
              return (
                <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.location}</p>
              )
            }
          },
          {
            title: "Contact", field: "contact", render: row => {
              return (
                <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.contact}</p>
              )
            }
          },
          {
            title: "Email", field: "email", render: row => {
              return (
                <p style={{ fontFamily: "Roboto", fontSize: 15 }}>{row.email}</p>
              )
            }
          },
          {
            title: "Status", field: "status", render: row => {
             
              return (

                <Switch checked={ row.status } disabled />

              )
            },
            editComponent: props => {
             
              return (
                <Switch checked={ props.value } onChange={e => {
                  props.onChange(e);
                  tmp_cat = e
                }} />
              )
            }
          }

        ]}

        data={info.map(o => ({ ...o }))}
        onRowClick={(e, RowData) => {
          dispatch(setCompany(RowData));
          History.push({ pathname: `${url}/companyDetails` })
        }}

        isLoading={loading || createComp[1].loading || editComp[1].loading}
        title={""}
        //onRowClick={(event, rowData) => handleSpecific(rowData._id)}
        components={{
          OverlayLoading: (props) => (
            <div
              {...props}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <BounceLoader color="#00acc1" size={50} {...props} />
            </div>
          ),

          Toolbar: props => (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", padding: 10 }}>
              <MTableToolbar {...props} />
              <div style={{ marginLeft: 10 }}>
                <Fab aria-label="add" style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                  <TextsmsIcon size="small" />
                </Fab>
              </div>
            </div>
          ),
        }}
        editable={{

          onRowAdd: newData =>
            new Promise((resolve) => {
              const str = newData.name
              const result = validateEmail(newData.email);
              const numRes = magicNumber(newData.contact);

              // console.log(numRes);
              if (str == "" || str.length < 4) {

                toaster.danger('The name should be greater than 5 characters');
                resolve();
              }
              else if (result == false || newData.email == "") {
                toaster.danger('Wrong email address supplied. try again');
                resolve();
              }
              else if (numRes == false || newData.contact == "") {
                toaster.danger('Wrong contact supplied. Contact must be in +25675..... format');
                resolve();
              }
              else if (newData.location == "" || newData.location.length < 5) {
                toaster.danger('Wrong location details');
                resolve();
              }
              else {

                createComp[0]({ variables: {  name: str, contact: newData.contact, email: newData.email, location: newData.location, number: 0, date: new Date().toISOString(), status: tmp_cat } }).then(res => {
                  //console.log(res)
                  if (res.data.createCompany !== null) {
                    setData(prev => [...prev, res.data.createCompany])
                    //users.refetch({ variables: { mac: state.mac.mac } });
                    //dispatch(update_Apn(state.id))
                    toaster.success("New company successfully created");
                    tmp_cat = false;
                    resolve();
                  }
                  if (res.errors) {
                    if(Array.isArray(res.errors)){
                      res.errors.map((error, index) => {
                        toaster.danger(error.message, {
                          id: `err_${index}`
                        })
                      })
                      resolve();
                    }
                    else{
                      toaster.danger("Something is wrong with your connection. Please check your internet");
                      resolve();
                    }
                  }

                }).catch(err => {

                  console.log(err)
                  resolve();
                })

              }

            }),

          onRowUpdate: (newData) =>
            new Promise((resolve) => {

              const str = newData.name
              const result = validateEmail(newData.email);
              const numRes = magicNumber(newData.contact);

              // console.log(numRes);
              if (str == "" || str.length < 3) {

                toaster.danger('The name should be greater than 5 characters');
                resolve();
              }
              else if (result == false || newData.email == "") {
                toaster.danger('Wrong email address supplied. try again');
                resolve();
              }
              else if (numRes == false || newData.contact == "") {
                toaster.danger('Wrong contact supplied. Contact must be in +25675..... format');
                resolve();
              }
              else if (newData.location == "" || newData.location.length < 5) {
                toaster.danger('Wrong location details');
                resolve();
              }
              else {
                const datArr = [ ...info]
                editComp[0]({ variables: {id: newData._id, name: str, contact: newData.contact, email: newData.email, location: newData.location,  status: newData.status } }).then(res => {
                  //console.log(res)
                  if (res.data.editCompany !== null) {
                    const arr = datArr.findIndex(item => item._id == newData._id);
                        datArr[arr] = res.data.editCompany;
                        // dispatch(modify_apn(res.data.updateApn));
                        setData(datArr);
                        toaster.success("Operation successful", {
                          zIndex: 20,
                        })
                        resolve()
                  }
                  if (res.errors) {
                    if(Array.isArray(res.errors)){
                      res.errors.map((error, index) => {
                        toaster.danger(error.message, {
                          id: `err_${index}`
                        })
                      })
                      resolve();
                    }
                    else{
                      toaster.danger("Something is wrong with your connection. Please check your internet");
                      resolve();
                    }
                  }

                }).catch(err => {

                  console.log(err)
                  resolve();
                })

              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve) => {
              resolve();
              //console.log(oldData)
            })

        }}
        icons={{
          Add: props => (
            <div>
              <Fab aria-label="add" {...props} style={{ color: "white", backgroundColor: "#00acc1" }} size="small">
                <AddBoxIcon size="small" />
              </Fab>
            </div>
          )
        }}


      />

    </div>
  )
}
export default Contractors;
