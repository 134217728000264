import React from "react";
import Highsites from "./Highsites";
import Sectors from "../Sectors/Sectors"
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";

const Apns = (props) => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Highsites />
      </Route>
      <Route path={`${path}/Clients`}>
        <Sectors />
      </Route>
    </Switch>
  );
};

export default Apns;
