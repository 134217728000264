import React from "react";
import Completed from "./Completed";
import CompletedDetails from "./CompleteDetails";
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";

const Comp = (props) => {

  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Completed />
      </Route>
      <Route path={`${path}/completedDetails`}>
        <CompletedDetails />
      </Route>
    </Switch>
  );
};

export default Comp;
