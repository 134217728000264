import {
  combineReducers
} from "redux";
import LoginReducer from "./login";
import AuthReducer from "./auth";
import AppUsersReducer from "./appUsers";
import SpecificReducer from "./specific";
import GetAllUSSDReducer from './ussd';
import SingleProfileReducer from './profile';
import LocationReducer from './location';
import HistoryReducer from './history';
import MacReducer from './_setMac';
import NameReducer from './_setHighsite';
import DetailReducer from './_setDetails';
import IdReducer from './_setID';
import SectorReducer from './_setSector';
import ApnReducer from './_setApn';
import CategoryReducer from './_setCategory';

const rootReducer = combineReducers({
  login: LoginReducer,
  auth: AuthReducer,
  appUsers: AppUsersReducer,
  specific: SpecificReducer,
  ussdUsers: GetAllUSSDReducer,
  profile: SingleProfileReducer,
  location: LocationReducer,
  hist: HistoryReducer,
  name: NameReducer,
  detail: DetailReducer,
  id: IdReducer,
  sector: SectorReducer,
  mac: MacReducer,
  apns: ApnReducer,
  category: CategoryReducer

});

export default rootReducer;